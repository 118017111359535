import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Homepage.css';
import Sol from '../components/Sol-sec'
import Blogs from '../components/Blogs';
import "../components/Awards.css";
import Brand1 from "../assets/image 2.png";
import Brand2 from "../assets/image 3.png";
import Brand3 from "../assets/image 4.png";
import Brand4 from "../assets/image 5.png";
import Brand5 from "../assets/image 9.png";
import Brand6 from "../assets/Group 72.png";
import News from '../components/newsletter';
import Banner1 from '../assets/home_articles_banner.jpg';
import Banner2 from '../assets/home_casestudy_banner.jpg';
import Banner3 from '../assets/home_elabs_banner.jpg';


const Home = (data) => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    speed: 800,
    cssEase: "linear"
  };

  const AutomaticCounter = ({ initialValue, finalValue, interval }) => {
    const [count, setCount] = useState(initialValue);

    // useEffect(() => {
    //   window.scrollTo(0, 0);
    // })
  
    useEffect(() => {
      const timer = setInterval(() => {
        if (count < finalValue) {
          setCount(count + 1);
        }
      }, interval);
  
      return () => clearInterval(timer);
    }, [count, finalValue, interval])
    return count;
  }
  const insightslider = data.data

  return (
    <div>
      <Helmet>
      <title>Home</title>
      <meta name='description' content='At Expertlancing, our dedicated team shares a passion for excellence and innovation, uniting diverse expertise to create meaningful solutions that make a difference.' />
      </Helmet>
      <section className='banner'>
        <Slider {...settings} className=''>
          <div className="relative bg-slate-950 text-white h-96 banner1 overflow-hidden w-full">
            {/* <div className="container mx-auto flex flex-col md:flex-row items-center my-12 md:my-10"> */}
            <div className="absolute z-40 top-2 left-2 xs:max-sm:left-0 xs:max-sm:px-5 flex flex-col w-8/12 xs:max-sm:w-full justify-center items-start p-8">
              <div className="tracking-widest text-[3em] font-semibold text-[white] uppercase xs:max-sm:text-[2em]">
                <h2>Unlock Insightful Perspectives</h2>
              </div>
              <div className="flex flex-col h-auto w-full">
                <p className='text-sm xs:max-sm:text-[14px] border border-white my-3 px-2 rounded w-fit'>Article</p>
                <p className='text-sm xs:max-sm:text-[14px] w-fit'>{insightslider[1].title}</p>
                <Link className='w-max no-underline text-center bg-white text-[rgb(47,47,47)] cursor-pointer font-semibold text-xs mt-5 px-[15px] py-[5px] rounded-[100px] border-2 border-solid border-[white]' to={`/${insightslider[1].title.replaceAll(' ', '-')}`}>Know More</Link>
              </div>
            </div>
            <div className='absolute top-0 left-0 w-full h-96 z-20 bg-black opacity-50'></div>
            <img className= 'w-full h-96 object-center object-cover' src={Banner1} alt='' />
            {/* </div> */}
          </div>
          <div className="relative bg-slate-950 text-white h-96 overflow-hidden banner2">
            {/* <div className="container mx-auto flex flex-col md:flex-row items-center my-12 md:my-10"> */}
            <div className="absolute z-40 top-2 left-2 xs:max-sm:left-0 xs:max-sm:px-5 flex flex-col w-8/12 xs:max-sm:w-full justify-center items-start p-8">
              <div className="tracking-widest text-[3em] font-semibold text-[white] uppercase xs:max-sm:text-[2em]">
                <h2>Discover Proven Success </h2>
              </div>
              <div className="flex flex-col h-auto w-full">
                <p className='text-sm xs:max-sm:text-[14px] border border-white my-3 px-2 rounded w-fit'>Case Study</p>
                <p className='text-sm xs:max-sm:text-[14px] w-fit'>{insightslider[2].title}</p>
                <Link className='w-max no-underline text-center bg-white text-[rgb(47,47,47)] cursor-pointer font-semibold text-xs mt-5 px-[15px] py-[5px] rounded-[100px] border-2 border-solid border-[white]' to={`/${insightslider[2].title.replaceAll(' ', '-')}`}>Know More</Link>
              </div>
            </div>
            <div className='absolute top-0 left-0 w-full h-96 z-20 bg-black opacity-50'></div>
            <img className='w-full h-96 object-center object-cover' src={Banner2} alt='' />
            {/* </div> */}
          </div>
          <div className="relative bg-slate-950 text-white h-96 overflow-hidden banner3">
            {/* <div className="container mx-auto flex flex-col md:flex-row items-center my-12 md:my-10"> */}
            <div className="absolute z-40 top-2 left-2 xs:max-sm:left-0 xs:max-sm:px-5 flex flex-col w-8/12 xs:max-sm:w-full justify-center items-start p-8">
              <div className="tracking-widest text-[3em] font-semibold text-[white] uppercase xs:max-sm:text-[2em]">
                <h2>Innovate with Intelligence</h2>
              </div>
              <div className="flex flex-col h-auto w-full">
                <p className='text-sm xs:max-sm:text-[14px] border border-white my-3 px-2 rounded w-fit'>Elabs</p>
                <p className='text-sm xs:max-sm:text-[14px] w-fit'>Innovate confidently, protect your ideas, and prosper with tailored IP consulting solutions.</p>
                <Link to='/labs' className='w-max no-underline text-center bg-white text-[rgb(47,47,47)] cursor-pointer font-semibold text-xs mt-5 px-[15px] py-[5px] rounded-[100px] border-2 border-solid border-[white]'>Know More</Link>
              </div>
            </div>
            <div className='absolute top-0 left-0 w-full h-96 z-20 bg-black opacity-50'></div>
            <img className='w-full h-96 object-center object-cover' src={Banner3} alt='' />
            {/* </div> */}
          </div>
        </Slider>
      </section >
      <section className='w-full h-fit flex flex-col justify-center items-center gap-5 py-10'>
        <h3 className='font-bold text-red-800 text-2xl xs:max-sm:text-[1.2em]'><span className='text-black'>Statistical</span> Insights</h3>
        <div className='flex flex-row items-start justify-center gap-20 w-9/12 xs:max-sm:gap-5 xs:max-sm:grid xs:max-sm:grid-rows-2 xs:max-sm:grid-cols-2'>
          <div className='flex flex-col justify-center items-center'>
            <span className='text-red-800 font-bold text-5xl xs:max-sm:text-2xl'><AutomaticCounter initialValue={0} finalValue={98} interval={30}/>%</span>
            <span className='font-semibold text-center text-xs xs:max-sm:text-xs'>Client Satisfaction Rate</span>
          </div>
          <div className='flex flex-col justify-center items-center'>
            <span className='text-red-800 font-bold text-5xl xs:max-sm:text-2xl'><AutomaticCounter initialValue={7900} finalValue={8000} interval={30}/>+</span>
            <span className='font-semibold text-center text-xs xs:max-sm:text-xs'>Successful Projects</span>
          </div>
          <div className='flex flex-col justify-center items-center'>
            <span className='text-red-800 font-bold text-5xl xs:max-sm:text-2xl'><AutomaticCounter initialValue={0} finalValue={80} interval={30}/>%</span>
            <span className='font-semibold text-center text-xs xs:max-sm:text-xs'>Client Retention Rate</span>
          </div>
          <div className='flex flex-col justify-center items-center' >
            <span className='text-red-800 font-bold text-5xl xs:max-sm:text-2xl'><AutomaticCounter initialValue={0} finalValue={25} interval={30}/>+</span>
            <span className='font-semibold text-center text-xs xs:max-sm:text-xs'>Global Industries Served</span>
          </div>
        </div>
      </section>
      <Sol artData ={insightslider} />
      <Blogs artData ={insightslider}/>
      <section className="flex flex-col items-center w-full py-10">
		  	<h2 className="hidden text-[1.2em] pb-5 font-bold xs:max-sm:block">Awards & <span className="text-red-800 font-bold">Recognition</span></h2>
          <div className='flex items-center justify-center'>
            <div className="slider w-5/12 xs:max-sm:w-full">
            	<div className='slide-track grid grid-rows-2 grid-flow-col gap-10 w-full xs:max-sm:gap-5'>
            		<div className='slide h-20 w-40 xs:max-sm:w-20 xs:max-sm:h-16'>
            			<img className="object-contain h-full w-full" src={Brand1} alt="" />
            		</div>
            		<div className='slide h-20 w-40 xs:max-sm:w-20 xs:max-sm:h-16'>
            			<img className="object-contain h-full w-full" src={Brand2} alt="" />
            		</div>
            		<div className='slide h-20 w-40 xs:max-sm:w-20 xs:max-sm:h-16'>
            			<img className="object-contain h-full w-full" src={Brand6} alt="" />
            		</div>
            		<div className='slide h-20 w-40 xs:max-sm:w-20 xs:max-sm:h-16'>
            			<img className="object-contain h-full w-full" src={Brand3} alt="" />
            		</div>
            		<div className='slide h-20 w-40 xs:max-sm:w-20 xs:max-sm:h-16'>
            			<img className="object-contain h-full w-full" src={Brand5} alt="" />
            		</div>
            		<div className='slide h-20 w-40 xs:max-sm:w-20 xs:max-sm:h-16'>
            			<img className="object-contain h-full w-full" src={Brand4} alt="" />
            		</div>
            	</div>
            </div>
		  		  <div className="w-6/12 text-end flex flex-col items-end xs:max-sm:hidden">
            	    	<h2 className="text-2xl font-bold">Awards &<br/><span className="text-red-800 font-bold">Recognition</span></h2>
		  		  	<p className="w-9/12 text-sm font-regular pt-5">Celebrating Excellence: Discover Our Award-Winning Journey of Trust and Quality!</p>
		  		  </div>
          </div>
		  	<p className="hidden w-9/12 text-center text-sm font-regular pt-5 xs:max-sm:block">Celebrating Excellence: Discover Our Award-Winning Journey of Trust and Quality!</p>
		  </section>
      <News/>
    </div >
  )
}

export default Home;