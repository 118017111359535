import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import aboutimg from "../assets/About Us banner.jpg";
import whoimg from "../assets/Who we are.jpg";
import what from "../assets/What we do.jpeg";
import deliver from "../assets/What we deliver.jpeg";
import "../pages/About.css";
import News from "../components/newsletter";
import csr1 from "../assets/CSR1.jpeg";
import csr2 from "../assets/CSR2.jpg";
import csr3 from "../assets/CSR3.jpg";
import { Link } from "react-router-dom";
import bdicon from "../assets/business_development_dept.png";
import opsicon from "../assets/ops_dept.png";
import rndicon from "../assets/research_and_development_dept.png";


const About = () => {
    // State to track the currently active tab
    const [activeNav, setActiveNav] = useState('overview');    
    // Function to handle navigation item click
    const handleNavClick = (id) => {
        setActiveNav(id);
    };
    // Set up the Intersection Observer
    useEffect(() => {
       const options = {
           root: null,
           rootMargin: '0px',
           threshold: 0.5 // Adjust the threshold for how much of the section should be visible
       };

       const observer = new IntersectionObserver((entries) => {
           entries.forEach(entry => {
               if (entry.isIntersecting) {
                   setActiveNav(entry.target.id);
               }
           });
       }, options);

       // Observe the sections
       if (overviewRef.current) observer.observe(overviewRef.current);
       if (whoRef.current) observer.observe(whoRef.current);
       if (peopleRef.current) observer.observe(peopleRef.current);
       if (doRef.current) observer.observe(doRef.current);
       if (deliverRef.current) observer.observe(deliverRef.current);

       // Clean up the observer on component unmount
       return () => {
           if (overviewRef.current) observer.unobserve(overviewRef.current);
           if (whoRef.current) observer.unobserve(whoRef.current);
           if (peopleRef.current) observer.unobserve(peopleRef.current);
           if (doRef.current) observer.unobserve(doRef.current);
           if (deliverRef.current) observer.unobserve(deliverRef.current);
       };
   }, []);
        // Refs for the sections
        const overviewRef = useRef(null);
        const whoRef = useRef(null);
        const peopleRef = useRef(null);
        const doRef = useRef(null);
        const deliverRef = useRef(null);



    return(
        <section className="flex flex-col items-center">
            <Helmet>
            <title>About Us</title>
            <meta name='description' content='Turning obstacles into opportunities is something we at Expertlancing are enthusiastic about. Our work is centered on providing our clients with a wide range of innovative and efficient solutions.' />
            </Helmet>
            <div className='relative text-white h-96 overflow-hidden w-full'>
                <img className='h-full w-full object-cover object-center brightness-[80%]' src={aboutimg} alt="" />
                <div className="absolute z-40 top-2 left-2 xs:max-sm:left-0 xs:max-sm:px-5 flex flex-col w-8/12 xs:max-sm:w-full justify-center items-start p-8">
                    <div className="tracking-widest text-[3em] font-semibold text-[white] uppercase xs:max-sm:text-[2em]">
                        <h2>Welcome to the next level</h2>
                    </div>
                    <div className="text-sm xs:max-sm:text-[14px] w-fit">
                        <p>Turning obstacles into opportunities is something we at Expertlancing are enthusiastic about. Our work is centered on providing our clients with a wide range of innovative and efficient solutions.</p>
                    </div>
                </div>
            </div>
            <div className='sticky top-14 bg-white z-50 w-full shadow-sm xs:max-sm:hidden'>
                <ul class='in-nav-active flex items-center justify-center gap-28 w-full py-5'>
                    <a href="#overview"><li className={activeNav === 'overview' ? 'active-inner' : ''} onClick={() => handleNavClick('overview')}>Overview</li></a>
                    <a href="#who"><li className={activeNav === 'who' ? 'active-inner' : ''} onClick={() => handleNavClick('who')}>Who are we</li></a>
                    <a href="#people"><li className={activeNav === 'people' ? 'active-inner' : ''} onClick={() => handleNavClick('people')}>Our People</li></a>
                    <a href="#do"><li className={activeNav === 'do' ? 'active-inner' : ''} onClick={() => handleNavClick('do')}>What we do</li></a>
                    <a href="#deliver"><li className={activeNav === 'deliver' ? 'active-inner' : ''} onClick={() => handleNavClick('deliver')}>What we deliver</li></a>
                </ul>
            </div>
            <div className="flex flex-col items-center xs:max-sm:w-full" id="overview" ref={overviewRef}>
                <h2 className="text-2xl font-semibold pt-14 xs:max-sm:text-[1.2em] xs:max-sm:w-10/12">Innovation Beyond Imagination</h2>
                <p className='font-medium text-black py-10 text-lg px-20 w-11/12 text-justify xs:max-sm:px-5 xs:max-sm:text-sm xs:max-sm:full'>
                    At ExpertLancing, we help businesses stay ahead in an evolving market with our <Link to={'/Strategic Market Insights'} className='font-semibold text-red-800 underline'>Strategic Market Insights</Link>, offering more than just data by providing foresight to navigate industry shifts. Our <Link to={'/Competitive & Consumer Intelligence'} className='font-semibold text-red-800 underline'>Competitive & Consumer Intelligence</Link>
                    &nbsp;delivers real-time competitor tracking and consumer behavior insights, allowing companies to fine-tune strategies. With a focus on the future, our <Link to={'/Innovation & Technology Strategy'} className='font-semibold text-red-800 underline'>Innovation & Technology Strategy</Link> identifies breakthrough technologies and aligns R&D with innovation for long-term growth. We also guide companies through globalization challenges 
                    and new markets with <Link to={'/Growth & Market Expansion'} className='font-semibold text-red-800 underline'>Growth & Market Expansion</Link> and <Link to={'/Emerging Opportunities & Ecosystem Mapping'} className='font-semibold text-red-800 underline'>Emerging Opportunities & Ecosystem Mapping</Link> , ensuring smoother market entry and expansion. Additionally, our&nbsp; 
                    <Link to={'/Operational Excellence & Benchmarking'} className='font-semibold text-red-800 underline'>Operational Excellence & Benchmarking</Link> services helps in optimizing processes and performance. Protecting intellectual property is essential, and our <Link to={'/IP Consulting Services'} className='font-semibold text-red-800 underline'>IP Consulting Services</Link> ensure robust protection, securing technological advancements. 
                    Combining IP protection, market research, and cutting-edge analytics, we empower businesses to innovate, secure leadership, and thrive in competitive ecosystems.
                </p>
            </div>
            <div className="w-9/12 py-20 xs:max-sm:py-10" id="who" ref={whoRef}>
                <h2 className="text-2xl font-semibold pb-10 w-full xs:max-sm:text-[1.2em]">Who we are</h2>
                <div className="flex items-start justify-center gap-5 h-96 relative xs:max-sm:flex-col xs:max-sm:h-auto">
                    <div className="fade-over-right w-6/12 z-30 xs:max-sm:hidden"></div>
                    <div className="w-6/12 z-40 xs:max-sm:w-full">
                        <p className="text-justify text-base xs:max-sm:text-sm">At Expertlancing, we are a team of dedicated professionals driven by a shared passion for excellence and innovation. Our diverse backgrounds and expertise unite us in a common mission: to create meaningful solutions that make a difference. We believe in the power of collaboration, integrity, and a customer-first approach, which guides everything we do.
                            <br/><br/>Founded on the principles of quality and reliability, we have grown into a trusted partner for clients across various industries worldwide. Our commitment to continuous learning and adaptation allows us to stay ahead in an ever-evolving landscape. We are not just a company—we are a community of thinkers, doers, and problem-solvers, all working together to shape a better future for our clients and the world around us.
                        </p>
                    </div>
                    <img className="w-6/12 h-full object-cover brightness-75 xs:max-sm:w-full xs:max-sm:brightness-100" src={whoimg} alt="" />
                </div>
            </div>
            {/* our-people */}
            <div className="relative py-10" id="people" ref={peopleRef}>
                <div className="absolute z-40 -top-5 w-full flex items-center justify-between pt-10">
                    <hr className="w-10/12"/>
                    <h2 className="bg-white w-5/12 p-2 text-center font-semibold text-2xl xs:max-sm:w-full xs:max-sm:text-[1.2em]">Our People</h2>
                    <hr className="w-10/12"/>
                </div>
                <div className="flex items-center justify-center gap-20 py-20 xs:max-sm:flex-col xs:max-sm:py-0 xs:max-sm:pt-20 xs:max-sm:gap-10">
                    <div className="flex items-start justify-start xs:max-sm:w-full gap-10">
                        <div className="about-people-imgs md-about"></div>
                        <div>
                            <h2 className="text-xl font-semibold xs:max-sm:text-[1em]">Manasi Dhawan</h2>
                            <p className="text-sm xs:max-sm:text-xs">CEO</p>
                            <Link to={'/ourpeople'} className="text-xs flex items-center justify-start gap-2 text-red-800">Read More<i className="fa fa-arrow-right text-xxs"></i></Link>
                        </div>
                    </div>
                    <div className="flex items-start justify-start xs:max-sm:w-full gap-10">
                        <div className="about-people-imgs gd-about"></div>
                        <div>
                            <h2 className="text-xl font-semibold xs:max-sm:text-[1em]">Gaurav Dhawan</h2>
                            <p className="text-sm xs:max-sm:text-xs">CSO</p>
                            <Link to={'/ourpeople'} className="text-xs flex items-center justify-start gap-2 text-red-800">Read More<i className="fa fa-arrow-right text-xxs"></i></Link>
                        </div>
                    </div>
                    <div className="flex items-start justify-start xs:max-sm:w-full gap-10">
                        <div className="about-people-imgs vp-about"></div>
                        <div>
                            <h2 className="text-xl font-semibold xs:max-sm:text-[1em]">Vinod Singh</h2>
                            <p className="text-sm xs:max-sm:text-xs">VP</p>
                            <Link to={'/ourpeople'} className="text-xs flex items-center justify-start gap-2 text-red-800">Read More<i className="fa fa-arrow-right text-xxs"></i></Link>
                        </div>
                    </div>
                </div>
                <hr className='xs:max-sm:hidden'/>
            </div>
            <div className="w-9/12 pt-10 pb-20 xs:max-sm:pb-10" id="do" ref={doRef}>
                <h2 className="text-2xl font-semibold pb-10 w-full xs:max-sm:text-[1.2em]">What we do</h2>
                <div className="flex items-start justify-center gap-5 h-96 relative xs:max-sm:flex-col xs:max-sm:h-auto">
                    <div className="fade-over-left w-6/12 z-30 xs:max-sm:hidden"></div>
                    <img className="w-6/12 h-full object-cover brightness-75 xs:max-sm:w-full xs:max-sm:brightness-100" src={what} alt="" />
                    <div className="w-6/12 z-40 xs:max-sm:w-full">
                        <p className="text-justify text-base xs:max-sm:text-sm">At Expertlancing, we are passionate about transforming challenges into opportunities. Our work revolves around delivering innovative and effective solutions that cater to the diverse needs of our clients. Whether it's through cutting-edge technology, creative problem-solving, or strategic thinking, we are committed to making a positive impact.
                            <br/><br/>We pride ourselves on our ability to adapt to the ever-changing landscape, ensuring that our offerings remain relevant and valuable. Collaboration is at the heart of what we do, allowing us to build strong partnerships and deliver results that exceed expectations. By staying focused on quality, integrity, and continuous improvement, we help our clients achieve their goals, grow their businesses, and thrive in their industries.
                        </p>
                    </div>
                </div>
            </div>
            {/* dept-card-graphic */}
            <div className="flex flex-col justify-start gap-10 w-full h-72 py-10 px-20 xs:max-sm:px-10 xs:max-sm:h-fit bg-red-600 relative">
                <h2 className="text-2xl font-semibold text-white xs:max-sm:text-[1.2em]">Careers At ExpertLancing</h2>
                <div className="flex items-center justify-center gap-10 xs:max-sm:flex-col xs:max-sm:gap-5">
                    <Link to= {'/career'} className='w-full'>
                        <div class="flex flex-row items-center justify-center gap-3 subsec border border-white h-16 w-full px-7 relative xs:max-sm:justify-between">
                            <img src={bdicon} alt="card-icon" className="bg-red-600 py-1 absolute top-2 -left-3 w-8" />
                            <h2 className="text-sm font-semibold text-white">Business Development</h2>
                            <i className='fa fa-long-arrow-right text-white' aria-hidden='true'></i>
                            {/* <p className="text-xs">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aperiam perspiciatis voluptatem vel nemo aspernatur neque debitis.</p> */}
                        </div>
                    </Link>
                    <Link to= {'/career'} className='w-full'>
                        <div class="flex flex-row items-center justify-center gap-3 subsec border border-white h-16 w-full px-7 relative xs:max-sm:justify-between">
                            <img src={opsicon} alt="card-icon" className="bg-red-600 py-1 absolute top-2 -left-3 w-8" />
                            <h2 className="text-sm font-semibold text-white">Research and Development</h2>
                            <i className='fa fa-long-arrow-right text-white' aria-hidden='true'></i>
                            {/* <p className="text-xs">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aperiam perspiciatis voluptatem vel nemo aspernatur neque debitis.</p> */}
                        </div>
                    </Link>
                    <Link to= {'/career'} className='w-full'>
                        <div class="flex flex-row items-center justify-center gap-3 subsec border border-white h-16 w-full px-7 relative xs:max-sm:justify-between">
                            <img src={rndicon} alt="card-icon" className="bg-red-600 py-1 absolute top-2 -left-3 w-8" />
                            <h2 className="text-sm font-semibold text-white">Operations</h2>
                            <i className='fa fa-long-arrow-right text-white' aria-hidden='true'></i>
                            {/* <p className="text-xs">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aperiam perspiciatis voluptatem vel nemo aspernatur neque debitis.</p> */}
                        </div>
                    </Link>
                    <Link to={'/career'} className="absolute bottom-5 right-10 text-white underline hover:text-black xs:max-sm:text-xs xs:max-sm:bottom-2"> View all openings </Link>
                </div>
            </div>
            {/* what-we-deliver */}
            <div className="w-9/12 py-20 xs:max-sm:py-10" id="deliver" ref={deliverRef}>
                <h2 className="text-2xl font-semibold pb-10 w-full xs:max-sm:text-[1.2em]">What we Deliver</h2>
                <div className="flex items-start justify-center gap-5 h-96 relative xs:max-sm:flex-col xs:max-sm:h-auto">
                    <div className="fade-over-right w-6/12 z-30 xs:max-sm:hidden"></div>
                    <div className="w-6/12 z-40 xs:max-sm:w-full">
                        <p className="text-justify text-base xs:max-sm:text-sm">At Expertlancing, we are committed to delivering exceptional value through our innovative products and services. Our focus is on providing high-quality solutions that meet the unique needs of our customers, ensuring their success and satisfaction. We pride ourselves on our attention to detail, reliability, and customer-centric approach, which allows us to build strong, lasting relationships.
                            <br/><br/>Whether it's cutting-edge technology, personalized service, or unparalleled support, we deliver excellence in everything we do. Our goal is to empower our clients to achieve their goals with confidence, knowing they have a trusted partner by their side. With a dedication to continuous improvement and a passion for innovation, we consistently strive to exceed expectations and deliver results that make a difference.
                        </p>
                    </div>
                    <img className="w-6/12 h-full object-cover brightness-75 xs:max-sm:w-full xs:max-sm:brightness-100" src={deliver} alt="" />
                </div>
            </div>
            {/* csr */}

            <div className='w-9/12 py-16 xs:max-sm:py-10'>
                <h2 className='text-[1.2em] font-semibold hidden xs:max-sm:block'>Our Initiatives</h2>
                <div className="flex items-center justify-center gap-10 xs:max-sm:flex-col xs:max-sm:px-10 xs:max-sm:pt-10">
                    <div className="w-72 h-80">
                        <img className='h-2/4 w-full brightness-75 object-cover' src={csr1} alt="" />
                        <div className="bg-darkgray text-white p-5">
                            <h2 className="text-xl font-semibold xs:max-sm:text-[1em]">Greening Our Future</h2>
                            <p className="text-sm pt-3 xs:max-sm:text-xs">Planting Trees to Promote Environmental Sustainability, Enhance Local Ecosystems, and Foster a Greener, Healthier Community for Future Generations.</p>
                        </div>
                    </div>
                    <div className="w-72 h-80">
                        <img className='h-2/4 w-full brightness-75 object-cover' src={csr2} alt="" />
                        <div className="bg-darkgray text-white p-5">
                            <h2 className="text-xl font-semibold xs:max-sm:text-[1em]">Caring for Our Elderly</h2>
                            <p className="text-sm pt-3 xs:max-sm:text-xs">Transforming Lives at Old Age Homes with Generous Donations, Ensuring Unparalleled Comfort, Joy, and Dignity for Our Elderly, Uplifting the Entire Community.</p>
                        </div>
                    </div>
                    <div className="w-72 h-80">
                        <img className='h-2/4 w-full brightness-75 object-cover' src={csr3} alt="" />
                        <div className="bg-darkgray text-white p-5">
                            <h2 className="text-xl font-semibold xs:max-sm:text-[1em]">Nurturing Young Minds</h2>
                            <p className="text-sm pt-3 xs:max-sm:text-xs">Empowering Underprivileged Children with Educational Resources, Opening Doors to Limitless Opportunities, and Shaping Bright Futures Through Generous Donations.</p>
                        </div>
                    </div>
                </div>
            </div>
            <News />
        </section>
    )
}

export default About