import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import CTAimg from "../assets/ctaimg.png";
import vector from "../assets/Vector.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const SubIndustry = (data) => {
    document.title = `${data.data.title}`;

    const [activeNav, setActiveNav] = useState('overview');

    const overviewRef = useRef(null);
    const technologyRef = useRef(null);
    const clientRef = useRef(null);

    const handleNavClick = (id) => {
        setActiveNav(id);
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    };

    const SubCatBanner = `/industry_img/${data.data.banner}`;
    const infographicBanner = `/industry_img/${data.data.infographic}`;

    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 800,
        cssEase: "linear"
    };

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setActiveNav(entry.target.id);
                }
            });
        }, options);

        if (overviewRef.current) observer.observe(overviewRef.current);
        if (technologyRef.current) observer.observe(technologyRef.current);
        if (clientRef.current) observer.observe(clientRef.current);

        return () => {
            if (overviewRef.current) observer.unobserve(overviewRef.current);
            if (technologyRef.current) observer.unobserve(technologyRef.current);
            if (clientRef.current) observer.unobserve(clientRef.current);
        };
    }, []);

    return (
        <section className='flex flex-col items-center justify-center'>
            <div className='ind-banner overflow-hidden h-96 w-full relative'>
                <img className='h-full w-full object-cover brightness-50' src={SubCatBanner} alt="" />
                <div className="absolute top-0 flex flex-col w-1/2 justify-center items-start p-8 xs:max-sm:w-full">
                    <div className="tracking-widest text-[3em] font-semibold text-[white] uppercase xs:max-sm:text-[2em]">
                        <h2>{data.data.title}</h2>
                    </div>
                    <div className="text-sm xs:max-sm:text-[14px] text-white">
                        <p>{data.data.tagline}</p>
                    </div>
                </div>
            </div>
            <div className='sticky top-14 bg-white z-50 w-full shadow-sm'>
                <ul className='in-nav-active flex items-center justify-center gap-28 w-full py-5 xs:max-sm:gap-8 xs:max-sm:text-sm'>
                    <li className={activeNav === 'overview' ? 'active-inner' : ''} onClick={() => handleNavClick('overview')}>Overview</li>
                    <li className={activeNav === 'technology' ? 'active-inner' : ''} onClick={() => handleNavClick('technology')}>Technology</li>
                    <li className={activeNav === 'client' ? 'active-inner' : ''} onClick={() => handleNavClick('client')}>Client Success</li>
                </ul>
            </div>
            <div className='flex flex-col items-center' id="overview" ref={overviewRef}>
                <p className='w-10/12 py-12 text-justify xs:max-sm:text-sm'>
                    {data.data.overview}
                </p>
            </div>
            <div className="infographic w-10/12" id='technology' ref={technologyRef}>
                <img src={infographicBanner} alt=""/>
            </div>
            <div id="client" className="testimonies relative w-7/12 h-40 my-10 xs:max-sm:w-10/12" ref={clientRef}>
                <h2 className="text-xl font-semibold text-center xs:max-sm:text-[1.2em]">Actions Speak Louder Than Words</h2>
                <img className="absolute bottom-0 right-0" src={vector} alt="" />
                <Slider {...settings} className='py-10'>
                    <div className='!flex flex-col items-start'>
                        <p className="text-sm">{data.data.testimonial}</p>
                        <span className="w-full text-xs text-red-800">~{data.data.testimonial_name}</span>
                    </div>
                    <div className='!flex flex-col items-start'>
                        <p className="text-sm">{data.data.testimonial2}</p>
                        <span className="w-full text-xs text-red-800">~{data.data.testimonial_name2}</span>
                    </div>
                    <div className='!flex flex-col items-start'>
                        <p className="text-sm">{data.data.testimonial3}</p>
                        <span className="w-full text-xs text-red-800">~{data.data.testimonial_name3}</span>
                    </div>
                </Slider>
            </div>
            <div className='flex justify-center gap-10'>
                <img className='block xs:max-sm:hidden'  src={CTAimg} alt="" />
                <div className='flex flex-col items-center gap-5 py-10'>
                    <h2 className='font-semibold text-2xl xs:max-sm:text-[1.2em]'>We Breathe <span className='text-red-800'>Zero Defect.</span></h2>
                    <img className='hidden xs:max-sm:block xs:max-sm:w-40' src={CTAimg} alt="" />
                    <Link to="/contact" className='border rounded py-2 px-5 bg-white text-red-800 font-semibold xs:max-sm:text-sm'>Connect with us</Link>
                </div>
            </div>
        </section>
    );
}

export default SubIndustry;
