import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import fetchAndReadExcel from '../components/fetchAndReadExcel';
import { format } from 'date-fns';
import "../pages/Insights2.css";


const Insights2 = () => {
    const [insights, setInsights] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isIndustriesVisible, setIndustriesVisible] = useState(false);
    const [isSolutionsVisible, setSolutionsVisible] = useState(false);
    const [selectedIndustries, setSelectedIndustries] = useState([]);
    const [isAnyChecked, setIsAnyChecked] = useState(false); // New state to track if any checkbox is checked
    const [filter, setFilter] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('All');

    useEffect(() => {
        const getData = async () => {
            try {
                const jsonData = await fetchAndReadExcel();
                let data = jsonData['data3'];
                
                // Sort the data by date (assuming the date field is named 'date' and is in ISO format)
                data = data.sort((a, b) => new Date(b.Submitted_on) - new Date(a.Submitted_on));
                
                setInsights(data);
                setFilter(data); // Show all articles by default
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        getData();
    }, []); 
    useEffect(() => {
        // Filter articles based on selected industries
        let filteredArticles = insights;
        if (selectedIndustries.length > 0) {
            filteredArticles = insights.filter(article =>
                selectedIndustries.some(industry =>
                    Object.keys(article).includes(industry)
                )
            );
        }

        // Further filter based on selected category
        if (selectedCategory !== 'All') {
            filteredArticles = filteredArticles.filter(article =>
                article.insight_type === selectedCategory
            );
        }

        setFilter(filteredArticles);
    }, [selectedIndustries, insights, selectedCategory]);

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        setSelectedIndustries(prevState =>
            checked ? [...prevState, value] : prevState.filter(industry => industry !== value)
        );

        // Update isAnyChecked state based on the checkbox status
        setIsAnyChecked(checked || selectedIndustries.length > 0);
    };

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
    };

    const toggleIndustriesVisibility = () => {
        setIndustriesVisible(!isIndustriesVisible);
    };

    const toggleSolutionsVisibility = () => {
        setSolutionsVisible(!isSolutionsVisible);
    };

    if (loading) return <p className='opacity-0'>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    function ExcelDateToJSDate(serial) {
        const utc_days = Math.floor(serial - 25569);
        const utc_value = utc_days * 86400 * 1000; // Convert to milliseconds
        const date_info = new Date(utc_value);
    
        const fractional_day = serial - Math.floor(serial) + 0.0000001;
        let total_seconds = Math.floor(86400 * fractional_day);
        const seconds = total_seconds % 60;
        total_seconds -= seconds;
        const hours = Math.floor(total_seconds / (60 * 60));
        const minutes = Math.floor(total_seconds / 60) % 60;
    
        // Create a new date with valid values
        const year = date_info.getFullYear();
        const month = date_info.getMonth();
        const day = date_info.getDate();
    
        // Ensure the values are valid
        if (isNaN(year) || isNaN(month) || isNaN(day) || 
            isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
            return null; // or handle error as appropriate
        }
    
        return new Date(year, month, day, hours, minutes, seconds);
        
    }

    const filter_list = [];
    insights.forEach(element => {
        if(element['Expert_Corner'] === 'Y' && filter_list.length < 4){
            filter_list.push((
                <div className="relative flex items-start w-full h-6/12">
                    <div className="relative flex flex-col gap-5 w-6/12 h-full p-5 bg-gray-100">
                        <div className="flex items-center justify-between">
                            <p className="text-xs text-gray-400 xs:max-sm:text-[10px]">{element['Prepared_By']}</p>
                            <p className="text-xs text-gray-400 xs:max-sm:text-[10px]">{element['insight_type']}</p>
                        </div>
                        <h2 className="text-xl pb-10 xs:max-sm:text-[14px]">{element['title']}</h2>
                        <div className="absolute bottom-5 flex items-center justify-between">
                            <Link className="text-xs text-gray-400 hover:text-red-800" to={`/${element['title'].replaceAll(' ', '-')}`}>Read More</Link>
                            
                        </div>
                    </div>
                    <p className="absolute top-5 right-5 text-xs text-gray-800 bg-white p-1 rounded xs:max-sm:text-[10px]">{format(new Date(ExcelDateToJSDate(element['Submitted_on']).toDateString()), 'EEEE, MMM, d, yyyy')}</p>
                    <img className="w-6/12 h-full object-cover" src={`/insights_img/${element['main_image_all']}`} alt="" />
                </div>
            ));
        }
    });
    return (
        <section className="flex flex-col items-center">
            <Helmet>
            <title>Insights</title>
            <meta name='description' content='Discover our expertly crafted insights covering a wide range of dynamic industries.' />
            </Helmet>
            <div className="relative flex items-center justify-start w-full"> {/* justify-around for news view */}
                <div class="relative z-40 xs:max-sm:px-5 flex flex-col gap-5 h-96 w-6/12 xs:max-sm:w-full items-start p-8">
                  <div className="tracking-widest text-[2em] font-semibold text-[white] uppercase xs:max-sm:text-[1.2em]">
                    <h2>Fresh Perspectives. <br /> Bold Solutions.</h2>
                  </div>
                  <div className="flex flex-col h-auto w-full">
                    {/* <p className='text-sm xs:max-sm:text-[14px] border border-white text-white my-3 px-2 rounded w-fit'>News</p> */}
                    <p className='text-sm w-fit text-white'>Welcome to our Insights Hub, where innovation meets expertise. We’re a dynamic team passionate about driving change. Dive into our blogs and case studies for fresh, actionable ideas that challenge conventional thinking and empower your business to thrive.</p>
                    {/* <Link className='w-max no-underline text-center bg-white text-[rgb(47,47,47)] cursor-pointer font-semibold text-xs mt-5 px-[15px] py-[5px] rounded-[100px] border-2 border-solid border-[white]' to={`/`}>Know More</Link> */}
                  </div>
                </div>
                {/* <div className="relative z-40 w-4/12 flex flex-col items-center justify-around h-96 p-8">
                    <div className="flex items-center justify-between gap-5 bg-white w-full p-5 rounded">
                        <img className="w-4/12 h-full object-cover rounded"  src={`/insights_img/65_1.jpg`} alt="" />
                        <div className="flex flex-col items-start justify-between h-full w-8/12">
                            <p className="text-sm">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                            <Link className='w-max no-underline text-center text-[rgb(47,47,47)] cursor-pointer font-semibold text-xs' to={`/`}>Read More</Link>
                        </div>
                    </div>
                    <div className="flex items-center justify-between gap-5 bg-white w-full p-5 rounded">
                        <img className="w-4/12 h-full object-cover rounded"  src={`/insights_img/65_1.jpg`} alt="" />
                        <div className="flex flex-col items-start justify-between h-full w-8/12">
                            <p className="text-sm">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                            <Link className='w-max no-underline text-center text-[rgb(47,47,47)] cursor-pointer font-semibold text-xs' to={`/`}>Read More</Link>
                        </div>
                    </div>
                </div> */}
                <img className="absolute z-20 top-0 left-0 h-full w-full object-cover brightness-50" src={`/insights_img/insightsmainbg2.jpg`} alt="" />
            </div>
            <div className='sticky top-14 bg-white z-50 w-full shadow-sm xs:max-sm:hidden'>
                <ul className='flex items-center justify-center gap-28 w-full py-5'>
                    <li className={`cursor-pointer ${selectedCategory === 'All' ? 'text-red-800' : ''}`} onClick={() => handleCategoryChange('All')}>All</li>
                    <li className={`cursor-pointer ${selectedCategory === 'Article' ? 'text-red-800' : ''}`} onClick={() => handleCategoryChange('Article')}>Articles</li>
                    <li className={`cursor-pointer ${selectedCategory === 'Case Study' ? 'text-red-800' : ''}`} onClick={() => handleCategoryChange('Case Study')}>Case Studies</li>
                    <a className="absolute right-10 text-white bg-red-800 p-2" href="#EC">Expert's Corner</a>
                </ul>
            </div>
            <div className="flex items-start justify-start gap-10 m-10 xs:max-sm:flex-col xs:max-sm:m-5 ">
                <div className="w-3/12 h-full xs:max-sm:w-full xs:max-sm:hidden">
                    <div>
                        <h2 className="border border-white border-b-red-800 p-4 cursor-pointer" onClick={toggleIndustriesVisibility}>Industries <i className={`fa fa-caret-down text-xs transition-transform duration-300 ${isIndustriesVisible ? 'rotate-180' : ''}`} aria-hidden="true"></i></h2>
                        <ul className={`h-60 overflow-hidden overflow-y-auto flex flex-wrap pt-5 transition-all duration-300 ${isIndustriesVisible ? 'block' : 'hidden'}`}>
                            {/* Checkbox items */}
                            <li className="flex items-center justify-start gap-5 pl-4"><input className="hidden chk-filter" type="checkbox" id="Chemicals" value="Chemicals" onChange={handleCheckboxChange} /><label className="fil-label" htmlFor="Chemicals">Chemicals</label></li>
                            <li className="flex items-center justify-start gap-5 pl-4"><input className="hidden chk-filter" type="checkbox" id="Food and Nutrition" value="Food and Nutrition" onChange={handleCheckboxChange} /><label className="fil-label" htmlFor="Food and Nutrition">Food and Nutrition</label></li>
                            <li className="flex items-center justify-start gap-5 pl-4"><input className="hidden chk-filter" type="checkbox" id="Energy and Natural Resources" value="Energy and Natural Resources" onChange={handleCheckboxChange} /><label className="fil-label" htmlFor="Energy and Natural Resources">Energy and Natural Resources</label></li>
                            <li className="flex items-center justify-start gap-5 pl-4"><input className="hidden chk-filter" type="checkbox" id="Home and Personal Care" value="Home and Personal Care" onChange={handleCheckboxChange} /><label className="fil-label" htmlFor="Home and Personal Care">Home and Personal Care</label></li>
                            <li className="flex items-center justify-start gap-5 pl-4"><input className="hidden chk-filter" type="checkbox" id="Healthcare and Life Sciences" value="Healthcare and Life Sciences" onChange={handleCheckboxChange} /><label className="fil-label" htmlFor="Healthcare and Life Sciences">Healthcare and Life Sciences</label></li>
                            <li className="flex items-center justify-start gap-5 pl-4"><input className="hidden chk-filter" type="checkbox" id="Mobility" value="Mobility" onChange={handleCheckboxChange} /><label className="fil-label" htmlFor="Mobility">Mobility</label></li>
                            <li className="flex items-center justify-start gap-5 pl-4"><input className="hidden chk-filter" type="checkbox" id="Industrials" value="Industrials" onChange={handleCheckboxChange} /><label className="fil-label" htmlFor="Industrials">Industrials</label></li>
                            <li className="flex items-center justify-start gap-5 pl-4"><input className="hidden chk-filter" type="checkbox" id="Technology Media and Telecom" value="Technology Media and Telecom" onChange={handleCheckboxChange} /><label className="fil-label" htmlFor="Technology Media and Telecom">Technology Media and Telecom</label></li>
                        </ul>
                    </div>
                    <div>
                        <h2 className="border border-white border-b-red-800 p-4 cursor-pointer" onClick={toggleSolutionsVisibility}>Solutions <i className={`fa fa-caret-down text-xs transition-transform duration-300 ${isSolutionsVisible ? 'rotate-180' : ''}`} aria-hidden="true"></i></h2>
                        <ul className={`h-60 overflow-hidden overflow-y-auto flex flex-wrap pt-5 transition-all duration-300 ${isSolutionsVisible ? 'block' : 'hidden'}`}>
                            <li className="flex items-center justify-start gap-5 pl-4"><input className="hidden chk-filter" type="checkbox" id="IPConsultingServices" value="IP Consulting Services" onChange={handleCheckboxChange} /><label className="fil-label" htmlFor="IPConsultingServices">IP Consulting Services</label></li>
                            <li className="flex items-center justify-start gap-5 pl-4"><input className="hidden chk-filter" type="checkbox" id="StrategicMarketInsights" value="Strategic Market Insights" onChange={handleCheckboxChange} /><label className="fil-label" htmlFor="StrategicMarketInsights">Strategic Market Insights</label></li>
                            <li className="flex items-center justify-start gap-5 pl-4"><input className="hidden chk-filter" type="checkbox" id="Competitive&ConsumerIntelligence" value="Competitive & Consumer Intelligence" onChange={handleCheckboxChange} /><label className="fil-label" htmlFor="Competitive&ConsumerIntelligence">Competitive & Consumer Intelligence</label></li>
                            <li className="flex items-center justify-start gap-5 pl-4"><input className="hidden chk-filter" type="checkbox" id="Innovation&TechnologyStrategy" value="Innovation & Technology Strategy" onChange={handleCheckboxChange} /><label className="fil-label" htmlFor="Innovation&TechnologyStrategy">Innovation & Technology Strategy</label></li>
                            <li className="flex items-center justify-start gap-5 pl-4"><input className="hidden chk-filter" type="checkbox" id="Growth&MarketExpansion" value="Growth & Market Expansion" onChange={handleCheckboxChange} /><label className="fil-label" htmlFor="Growth&MarketExpansion">Growth & Market Expansion</label></li>
                            <li className="flex items-center justify-start gap-5 pl-4"><input className="hidden chk-filter" type="checkbox" id="OperationalExcellence&Benchmarking" value="Operational Excellence & Benchmarking" onChange={handleCheckboxChange} /><label className="fil-label" htmlFor="OperationalExcellence&Benchmarking">Operational Excellence & Benchmarking</label></li>
                            <li className="flex items-center justify-start gap-5 pl-4"><input className="hidden chk-filter" type="checkbox" id="EmergingOpportunities&Ecosystem Mapping" value="Emerging Opportunities & Ecosystem Mapping" onChange={handleCheckboxChange} /><label className="fil-label" htmlFor="EmergingOpportunities&Ecosystem Mapping">Emerging Opportunities & Ecosystem Mapping</label></li>
                        </ul>
                    </div>
                </div>
                <div className={`hidden w-full overflow-hidden xs:max-sm:block`}>
                    <div className="flex items-center justify-between border border-white border-b-red-800">
                        <h2 className= "cursor-pointer text-xs bg-gray-100 p-2" onClick={toggleIndustriesVisibility}>Industries <i className={`fa fa-caret-down text-xs transition-transform duration-300 ${isIndustriesVisible ? 'rotate-180' : ''}`} aria-hidden="true"></i></h2>
                        <h2 className= "cursor-pointer text-xs bg-gray-100 p-2" onClick={toggleSolutionsVisibility}>Solutions <i className={`fa fa-caret-down text-xs transition-transform duration-300 ${isSolutionsVisible ? 'rotate-180' : ''}`} aria-hidden="true"></i></h2>
                        <a className="text-white text-xs bg-red-800 p-2" href="#EC">Expert's Corner</a>
                     </div>
                    <ul className={`pt-5 text-xxs transition-all duration-300 grid-cols-2 grid-rows-auto gap-2  ${isIndustriesVisible ? 'grid' : 'hidden'}`}>
                        {['Chemicals', 'Technology Media and Telecom', 'Energy and Natural Resources', 'Food and Nutrition', 'Healthcare and Life Sciences', 'Mobility', 'Industrials', 'Home and Personal Care'].map(industry => (
                            <li className={`flex items-center justify-center  ${selectedIndustries.includes(industry) ? 'text-white font-semibold bg-red-800 p-1 text-center rounded-full' : 'bg-gray-100 text-gray-500 font-semibold p-1 text-center rounded-full'}`} key={industry}>
                                <label>
                                    {industry}
                                    <input className={`hidden`} type="checkbox" id={industry} value={industry} onChange={handleCheckboxChange} />
                                </label>
                            </li>
                        ))}
                    </ul>
                    <ul className={`pt-5 text-xxs transition-all duration-300 grid-cols-2 grid-rows-auto gap-2  ${isSolutionsVisible ? 'grid' : 'hidden'}`}>
                        {['IP Consulting Services', 'Strategic Market Insights', 'Competitive & Consumer Intelligence', 'Innovation & Technology Strategy', 'Growth & Market Expansion', 'Operational Excellence & Benchmarking', 'Emerging Opportunities & Ecosystem Mapping'].map(solution => (
                            <li className={`flex items-center justify-center  ${selectedIndustries.includes(solution) ? 'text-white font-semibold bg-red-800 p-1 text-center rounded-full' : 'bg-gray-100 text-gray-500 font-semibold p-1 text-center rounded-full'}`} key={solution}>
                                <label>
                                    {solution}
                                    <input className={`hidden`}  type="checkbox" id={solution} value={solution} onChange={handleCheckboxChange} />
                                </label>
                            </li>
                        ))}
                    </ul>
                    
                </div>
                <div className="grid grid-cols-3 auto-rows-auto gap-10 w-9/12 p-2 xs:max-sm:flex xs:max-sm:flex-col xs:max-sm:items-center xs:max-sm:justify-center xs:max-sm:gap-5 xs:max-sm:w-full h-dvh overflow-x-hidden overflow-y-auto xs:max-sm:h-fit">
                    {filter.map((article, index) => (
                        <div className="cardcontainer h-[350px] relative rounded overflow-hidden xs:max-sm:hidden">
                            <div key={index} className={`art-card flex flex-col gap-2 bg-white p-5 h-full relative`}>
                                <p className="text-xs text-red-800 py-[2px]">{format(new Date(ExcelDateToJSDate(article.Submitted_on).toDateString()), 'EEEE, MMM, d, yyyy')}</p>
                                <p className="text-[14px] py-3">{article.title}</p>
                                <span className="text-[10px]">{article.Industry1}, {article.Industry2}, {article.Industry3}</span>
                                <p className="w-full text-right text-xs bg-white px-1 py-[2px]">{article.insight_type}</p>
                                <Link to={`/${article.title.replaceAll(' ', '-')}`} className="cardLink z-40 absolute bottom-3 right-3 flex items-center gap-2 text-xs text-red-800 w-fit">
                                    Read More
                                </Link>
                            </div>
                            <img className={`card-img absolute top-0 left-0 z-30 w-full h-full object-cover`} src={`/insights_img/${article.main_image_all}`} alt={article.title} />
                        </div>
                    ))}
                    {filter.map((article, index) => (
                        <div key={index} className="hidden bg-white rounded p-4 shadow relative xs:max-sm:block w-full">
                            <div className="flex items-center justify-center gap-2 relative overflow-hidden h-5/6">
                                <img className="object-cover w-6/12 h-[50px]" src={`/insights_img/${article.main_image_all}`} alt={article.title} />
                                <span className="text-[10px]">{article.Industry1}, {article.Industry2}, {article.Industry3}</span>
                            </div>
                            <div className="relative" >
                                <p className="text-[14px] pt-4 pb-8 ">{article.title}</p>
                                <div className="absolute bottom-0 flex items-center justify-between w-full ">
                                    <div className="flex items-center justify-center gap-2">
                                        <p className="text-xxs border border-red-800 rounded-sm text-red-800 bg-white font-semibold px-1 py-[2px]">{article.insight_type}</p>
                                        <p className="text-xxs border border-red-800 rounded-sm text-red-800 bg-white font-semibold px-1 py-[2px]">{format(new Date(ExcelDateToJSDate(article.Submitted_on).toDateString()), 'EEEE, MMM, d, yyyy')}</p>
                                    </div>
                                    <Link to={`/${article.title.replaceAll(' ', '-')}`} className="flex items-center gap-2 text-[11px] text-red-800">Read More <i className="fa fa-long-arrow-right" aria-hidden="true"></i></Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div id="EC" className="flex flex-col gap-10 w-10/12 pt-10 pb-20">
                <h2 className="text-3xl font-semibold xs:max-sm:text-[1.2em]">Expert's Corner</h2>
                <hr/>
                <div className="grid grid-cols-2 grid-rows-auto gap-10 xs:max-sm:grid-cols-1">
                    {filter_list}
                </div>
            </div>
        </section>
    );
};

export default Insights2;
