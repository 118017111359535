import React from "react";
import './Blogs.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useMediaQuery from './useMediaQuery';
import { Link } from "react-router-dom";
import { format } from 'date-fns';

const Blogs = ({ artData = [] }) => {  // Defaulting to an empty array if artData is undefined
  const isSmallScreen = useMediaQuery('(max-width: 600px)');

  // Slider settings
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "50px",
    slidesToShow: isSmallScreen ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    cssEase: "linear"
  };

  // Check if artData has data and slice it to get the first 8 items
  const articleData = artData && artData.length ? artData.slice(0, 8) : [];

  // Function to convert Excel date format to JS Date
  function ExcelDateToJSDate(serial) {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);

    const fractional_day = serial - Math.floor(serial) + 0.0000001;
    let total_seconds = Math.floor(86400 * fractional_day);
    const seconds = total_seconds % 60;
    total_seconds -= seconds;
    const hours = Math.floor(total_seconds / (60 * 60));
    const minutes = Math.floor(total_seconds / 60) % 60;

    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
  }

  return (
    <section className='mx-8 py-10 flex flex-col items-center justify-center gap-10'>
      <h3 className='text-xl xs:max-sm:text-[1.2em] font-semibold'>
        Fueling intellects with enlightening perspectives.
      </h3>
      <div className="slider-container w-11/12 h-full xs:max-sm:w-screen">
        <Slider {...settings}>
          {articleData.length > 0 ? (
            articleData.map((blog, index) => (
              <Link className="" to={`/${blog.title.replaceAll(' ', '-')}`} key={index}>
                <div className='slidecard'>
                  <img src={`/insights_img/${blog.main_image_all}`} alt={blog.title} />
                  <div className="slidecard-txt h-full">
                    <span className="slidecard-tag">{blog.insight_type}</span>&nbsp;&nbsp;&nbsp;
                    <span className="slidecard-tag">
                      {format(new Date(ExcelDateToJSDate(blog.Submitted_on).toLocaleDateString()), 'MMMM dd, yyyy')}
                    </span>
                    <h3>{blog.title}</h3>
                    <p className="truncate" dangerouslySetInnerHTML={{ __html: blog.Para_1 }}></p>
                    <Link to={`/${blog.title.replaceAll(' ', '-')}`} className='absolute bottom-5 right-2 w-full text-right flex items-center justify-end gap-5 xs:max-sm:gap-2 text-white text-xs z-30'>
                      Read More <i className='fa fa-chevron-right'></i>
                    </Link>
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <p>No articles available.</p>
          )}
        </Slider>
      </div>
    </section>
  );
};

export default Blogs;
