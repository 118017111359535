import React from 'react'
import { Link } from "react-router-dom";
import fetchAndReadExcel from '../components/fetchAndReadExcel';
import { useState, useEffect } from "react";
import { format } from 'date-fns';
import { el } from 'date-fns/locale';
import { it } from 'date-fns/locale/it';

const ExpertCorner = () => {
    const [insights, setInsights] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    useEffect(() => {
        const getData = async () => {
        const jsonData = await fetchAndReadExcel();
        let data = jsonData['data3'];
        
        // Sort the data by date (assuming the date field is named 'date' and is in ISO format)
        data = data.sort((a, b) => new Date(b.Submitted_on) - new Date(a.Submitted_on));
        
        setInsights(data);
        };
        getData();
    }, []);
    function ExcelDateToJSDate(serial) {
        const utc_days = Math.floor(serial - 25569);
        const utc_value = utc_days * 86400 * 1000; // Convert to milliseconds
        const date_info = new Date(utc_value);
    
        const fractional_day = serial - Math.floor(serial) + 0.0000001;
        let total_seconds = Math.floor(86400 * fractional_day);
        const seconds = total_seconds % 60;
        total_seconds -= seconds;
        const hours = Math.floor(total_seconds / (60 * 60));
        const minutes = Math.floor(total_seconds / 60) % 60;
    
        // Create a new date with valid values
        const year = date_info.getFullYear();
        const month = date_info.getMonth();
        const day = date_info.getDate();
    
        // Ensure the values are valid
        if (isNaN(year) || isNaN(month) || isNaN(day) || 
            isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
            return null; // or handle error as appropriate
        }
    
        return new Date(year, month, day);
        
    }
    const filter_list = [];
    const filter_date = {"January": [], "February": [], "March": [], "April": [], "May": [], "June": [], "July": [], "August": [], "September":[], "October": [], "November": [], "December": []};
    insights.forEach(element => {
        if(element['Expert_Corner'] === 'Y' && format(ExcelDateToJSDate(element['Submitted_on']), "yyyy") == '2024' && filter_date[format(ExcelDateToJSDate(element['Submitted_on']), "MMMM")].length < 4){
            filter_date[format(ExcelDateToJSDate(element['Submitted_on']), "MMMM")].push(element);
        }
    });
    console.log(filter_date)
    Object.keys(filter_date).reverse().forEach(ele => {
        if(filter_date[ele].length != 0){
            filter_list.push((
        <div className='w-11/12 pt-10'>

        <p className='w-full border-b-[1px] border-b-[#cacaca] pb-2 mb-5 text-sm text-[#888888]'>{ele} 2024</p>        

        <div className="grid grid-cols-4 auto-rows-auto gap-10 w-full p-2 xs:max-sm:flex xs:max-sm:flex-col xs:max-sm:items-center xs:max-sm:justify-center xs:max-sm:gap-5 xs:max-sm:w-full h-fit overflow-x-hidden xs:max-sm:h-fit">
        {filter_date[ele].map((item, index) => (
      <div key={index} className="cardcontainer h-[350px] relative rounded overflow-hidden xs:max-sm:hidden">
        <div className={`art-card flex flex-col gap-2 bg-white p-5 h-full relative`}>
          <p className="text-xs text-red-800 py-[2px]">{format(new Date(ExcelDateToJSDate(item['Submitted_on']).toDateString()), 'EEEE, MMM, d, yyyy')}</p>
          <p className="text-[14px] py-3">{item.title}</p>
          <span className="text-[10px]">{item.Industry1}, {item.Industry2}, {item.Industry3}</span>
          <p className="w-full text-right text-xs bg-white px-1 py-[2px]">{item.insight_type}</p>
          <Link to={`/${item.title.replaceAll(' ', '-')}`} className="cardLink z-40 absolute bottom-3 right-3 flex items-center gap-2 text-xs text-red-800 w-fit">
            Read More
          </Link>
        </div>
        <img className={`card-img absolute top-0 left-0 z-30 w-full h-full object-cover`} src={`/insights_img/${item.main_image_all}`} alt={item.title} />
      </div>
    ))}
        </div>
        </div>
            ));
        }
    });
    return (
        <section className="flex flex-col items-center">

            <div className="relative flex items-center justify-start w-full"> {/* justify-around for news view */}
                <div class="relative z-40 xs:max-sm:px-5 flex flex-col gap-5 h-96 w-6/12 xs:max-sm:w-full items-start p-8">
                    <div className="tracking-widest text-[2em] font-semibold text-[white] uppercase xs:max-sm:text-[1.2em]">
                        <h2>Fresh Perspectives. <br /> Bold Solutions.</h2>
                    </div>
                    <div className="flex flex-col h-auto w-full">
                        {/* <p className='text-sm xs:max-sm:text-[14px] border border-white text-white my-3 px-2 rounded w-fit'>News</p> */}
                        <p className='text-sm w-fit text-white'>Welcome to our Insights Hub, where innovation meets expertise. We’re a dynamic team passionate about driving change. Dive into our blogs and case studies for fresh, actionable ideas that challenge conventional thinking and empower your business to thrive.</p>
                        {/* <Link className='w-max no-underline text-center bg-white text-[rgb(47,47,47)] cursor-pointer font-semibold text-xs mt-5 px-[15px] py-[5px] rounded-[100px] border-2 border-solid border-[white]' to={`/`}>Know More</Link> */}
                    </div>
                </div>
                <img className="absolute z-20 top-0 left-0 h-96 w-full object-cover brightness-75" src={`/insights_img/insightsmain.jpg`} alt="" />
            </div>
            {filter_list}
        </section>
    )
}

export default ExpertCorner