import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
// import emailjs from "emailjs-com";
import News from "../components/newsletter";
// import CardIcon from "../assets/card_icon.svg";
import innovationicn from "../assets/career-icns/innovation_and_creativity.png";
import diversityicn from "../assets/career-icns/diversity_and_inclusion.png";
import balanceicn from "../assets/career-icns/worklife_balance_and_flexibility.png";
import learningicn from "../assets/career-icns/learning_and_development.png";
import funicn from "../assets/career-icns/employee_engegement.png"
import wellbeingicn from "../assets/career-icns/employee_wellbeing.png";
import Careerimg from "../assets/Career page banner.jpeg";
import careerline from "../assets/career-line-graphic.png";
import innernav1 from "../assets/Internal nav - Why join.jpeg";
import innernav2 from "../assets/internal nav - current openings.jpeg";
import meetinggif from "../assets/Cultural Video_compressed.mp4";
import ourpeoplegif from "../assets/Meet_our_people.mp4";
import glassdoorimg from "../assets/glassdoor.png";
import greatplace from "../assets/greatplace.png";

const Career = () => {
    // State to manage which dropdown is open
    const [openDropdown, setOpenDropdown] = useState('business-development');
    // const [selectedJob, setSelectedJob] = useState(null);
    // const [formData, setFormData] = useState({ name: '', email: '', message: '' });

//     const handleApplyClick = (job) => {
//       setSelectedJob(job);
//     };
  
//     const handleCloseForm = () => {
//       setSelectedJob(null);
//     };
  
//     const handleInputChange = (e) => {
//       const { name, value } = e.target;
//       setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = (e) => {
//       e.preventDefault();

//       // Use EmailJS to send the email
//       emailjs.send('service_h1hhb55', 'template_mibssfb', formData, 'l6vPLqmeRyWSqE6LN')
//           .then((result) => {
//               console.log('Email successfully sent:', result.text);
//               alert('Your query has been submitted!');
//               setSelectedJob(null); // Close the form after successful submission
//               setFormData({ name: '', email: '', message: '' }); // Clear form data
//           }, (error) => {
//               console.log('Email sending error:', error.text);
//               alert('There was an error sending your application. Please try again.');
//           });
//   };

    // Function to handle dropdown toggle
    const toggleDropdown = (category) => {
        setOpenDropdown(openDropdown === category ? '' : category);
    };


    const jobs = {
      'business-development': [
          { title: 'Associate – Client Development (APAC & Europe Region)', category: 'Business Development', experience: '6 Mo - 2 Yrs', pdfUrl: '/JD - Associate - Client Development - APAC & Europe.pdf' },
          { title: 'Associate – Client Development (US Region)', category: 'Business Development', experience: '1 - 2 Yrs', pdfUrl: '/JD - Associate - Client Development (US).pdf' },
          { title: 'Consultant – Client Development (US Region)', category: 'Business Development', experience: '4+ Yrs', pdfUrl: '/JD - Consultant - Client Development (US).pdf' },
          { title: 'Consultant – Client Development (APAC & Europe Region)', category: 'Business Development', experience: '4+ Yrs', pdfUrl: '/JD - Consultant - Client Development (APAC & Europe).pdf' },
          { title: 'Senior Associate – Client Development (APAC & Europe Region)', category: 'Business Development', experience: '2 - 4 Yrs', pdfUrl: '/JD - Sr. Associate - Client Development (APAC & Europe).pdf' },
          { title: 'Senior Associate – Client Development (US Region)', category: 'Business Development', experience: '2 - 4 Yrs', pdfUrl: '/JD - Sr. Associate - Client Development (US).pdf' },
          { title: 'Data Mining Executive', category: 'Business Development', experience: '6 Mo - 2 Yrs', pdfUrl: '/JD - Data Mining Executive.pdf' }
      ],
      'operations': [
          { title: 'Project Lead (Life Sciences)', category: 'Operations', experience: '3+ Yrs', pdfUrl: '/JD - Project Lead (Life Sciences).pdf' },
          { title: 'Team Lead (Hi-tech)', category: 'Operations', experience: '4+ Yrs', pdfUrl: '/Job Description - Team Lead (Hi-tech).pdf' },
          { title: 'Team Lead (Life Sciences)', category: 'Operations', experience: '4+ Yrs', pdfUrl: '/Job Description - Team Lead (Life Sciences).pdf' }
      ],
      'research-development': [
    ],
    'market-research':[
          { title: 'Consultant - MRBR', category: 'Market Research', experience: '4 - 6 Yrs', pdfUrl: '/Job Description - MRBR Team.pdf' }
      ]
  };

  const downloadPdf = (pdfUrl) => {
      // Create a link element and set it to the PDF URL
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.download = pdfUrl.split('/').pop(); // Use the file name from the URL
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  };

    return (
        <section className="flex flex-col items-center">
            <Helmet>
            <title>Careers</title>
            <meta name='description' content='Are you someone who believes in Zero Defect? If yes, then you are at the right place!' />
            </Helmet>
            <div className='ind-banner relative bg-slate-950 text-white h-96 overflow-hidden w-full'>
                <img className='h-full w-full object-cover brightness-50' src={Careerimg} alt="" />
                <div className="absolute z-40 top-2 left-2 xs:max-sm:left-0 xs:max-sm:px-5 flex flex-col w-8/12 xs:max-sm:w-full justify-center items-start p-8">
                    <div className="tracking-widest text-[3em] font-semibold text-[white] uppercase xs:max-sm:text-[1.8em]">
                        <h2>Careers At Expertlancing</h2>
                    </div>
                    <div className="text-sm xs:max-sm:text-[14px] w-fit">
                        <p>Are you someone who believes in Zero Defect?<br/>If yes, then you're at the right place!</p>
                    </div>
                </div>
            </div>
            <h2 className="text-2xl font-semibold w-full text-center py-8 text-red-800 xs:max-sm:text-[1.2em] xs:max-sm:w-10/12">Unlock your potential with Us: <br/><span className="text-lg text-black">Your journey to success starts here!</span></h2>
            <div className="flex relative items-center py-10 xs:max-sm:grid xs:max-sm:grid-rows-2 xs:max-sm:grid-cols-2 xs:max-sm:gap-5 xs:max-sm:w-10/12 xs:max-sm:h-fit">
                <div className="absolute left-24 flex flex-col items-center justify-center xs:max-sm:static xs:max-sm:z-30 ">
                    <img className="w-80 h-52 rounded object-cover brightness-75 xs:max-sm:h-32 xs:max-sm:w-full" src={innernav1} alt="" />
                    <a href="#whyjoin" className="flex flex-col -translate-y-1/2 justify-between bg-white shadow h-44 w-11/12 p-5 rounded xs:max-sm:h-28 xs:max-sm:-translate-y-1/3 xs:max-sm:text-[12px] xs:max-sm:p-2">
                        <p>Why Join Expertlancing?</p>
                        <i className="fa fa-long-arrow-right w-full text-right"></i>
                    </a>
                </div>
                <div className="flex flex-col gap-8 items-center justify-center absolute translate-x-1/2 left-1/4 xs:max-sm:hidden">
                    <a href="#life" className="flex flex-col justify-between bg-careerblue2 shadow text-white h-48 w-80 p-5 rounded">
                        <p>Life At Expertlancing</p>
                        <i className="fa fa-long-arrow-right w-full text-right"></i>
                    </a>
                    <a href="#meet" className="flex flex-col justify-between bg-careerblue1 shadow text-white h-48 w-80 p-5 rounded">
                        <p>Meet Our People</p>
                        <i className="fa fa-long-arrow-right w-full text-right"></i>
                    </a>
                </div>
                <div className="absolute right-24 flex flex-col items-center justify-center bottom-0 xs:max-sm:static xs:max-sm:z-30">
                    <img className="w-80 h-52 rounded object-cover object-bottom brightness-75 xs:max-sm:h-32 xs:max-sm:w-full" src={innernav2} alt="" />
                    <a href="#openings" className="flex flex-col -translate-y-1/2 justify-between bg-white shadow h-44 w-11/12 p-5 rounded xs:max-sm:h-28 xs:max-sm:-translate-y-1/3 xs:max-sm:text-[12px] xs:max-sm:p-2">
                        <p>Current Openings</p>
                        <i className="fa fa-long-arrow-right w-full text-right"></i>
                    </a>
                </div>
                <a href="#life" className="hidden flex-col justify-between bg-careerblue2 shadow text-white h-full rounded xs:max-sm:flex w-full text-[12px] p-2 z-30">
                    <p>Life At Expertlancing</p>
                    <i className="fa fa-long-arrow-right w-full text-right"></i>
                </a>
                <a href="#meet" className="hidden flex-col justify-between bg-careerblue1 shadow text-white h-full rounded xs:max-sm:flex w-full text-[12px] p-2 z-30">
                    <p>Meet Our People</p>
                    <i className="fa fa-long-arrow-right w-full text-right"></i>
                </a>
                <img className="xs:max-sm:absolute xs:max-sm:w-screen xs:max-sm:h-96 xs:max-sm:object-cover xs:max-sm:z-10" src={careerline} alt="" />
            </div>
            <div className="flex flex-col items-center w-9/12 py-10" id="whyjoin">
                <h2 className="text-left w-full text-2xl font-semibold xs:max-sm:text-[1.2em]">Why Join Expertlancing</h2>
                <p className="text-left w-full text-base py-5 xs:max-sm:text-sm">At Expertlancing, our culture is the heartbeat of our organization. We believe that a thriving workplace culture is the foundation upon which extraordinary achievements are built. Here's a glimpse into what makes our culture truly exceptional.</p>
                <div className="grid grid-cols-3 auto-rows-auto gap-10 pt-5  xs:max-sm:grid-cols-1">
                <div className='w-full'>
                        <div class="flex flex-col justify-start pt-2 gap-3 subsec border border-red-800 h-44 w-54 px-7 relative">
                            <img src={innovationicn} alt="card-icon" className="absolute top-2 -left-5 w-10 bg-white p-2 shadow rounded-full" />
                            <h2 className="text-lg font-semibold text-red-800">Innovation and creativity</h2>
                            <p className="h-40 text-xs truncate text-pretty">We foster an environment where innovative ideas are not only welcomed but encouraged. We believe that creativity drives progress, and our teams are empowered to think outside the box.</p>
                        </div>
                    </div>
                    <div className='w-full'>
                        <div class="flex flex-col justify-start pt-2 gap-3 subsec border border-red-800 h-44 w-54 px-7 relative">
                            <img src={diversityicn} alt="card-icon" className="absolute top-2 -left-5 w-10 bg-white p-2 shadow rounded-full" />
                            <h2 className="text-lg font-semibold text-red-800">Diversity and inclusion</h2>
                            <p className="h-40 text-xs truncate text-pretty">We celebrate diversity in all its forms. We know that our differences make us stronger, and we are committed to creating an inclusive workplace where every voice is heard and respected.</p>
                        </div>
                    </div>
                    <div className='w-full'>
                        <div class="flex flex-col justify-start pt-2 gap-3 subsec border border-red-800 h-44 w-54 px-7 relative">
                            <img src={learningicn} alt="card-icon" className="absolute top-2 -left-5 w-10 bg-white p-2 shadow rounded-full" />
                            <h2 className="text-lg font-semibold text-red-800">Continuous Learning</h2>
                            <p className="h-40 text-xs truncate text-pretty">We are a learning organization. We invest in the growth and development of our employees, offering opportunities for training, skill enhancement, and career advancement.</p>
                        </div>
                    </div>
                    <div className='w-full'>
                        <div class="flex flex-col justify-start pt-2 gap-3 subsec border border-red-800 h-44 w-54 px-7 relative">
                            <img src={balanceicn} alt="card-icon" className="absolute top-2 -left-5 w-10 bg-white p-2 shadow rounded-full" />
                            <h2 className="text-lg font-semibold text-red-800">Work-life balance</h2>
                            <p className="h-40 text-xs truncate text-pretty">We understand that a balanced life leads to happier and more productive employees. Our flexible work policies and supportive management ensure that our team members can excel in both their professional and personal lives.</p>
                        </div>
                    </div>
                    <div className='w-full'>
                        <div class="flex flex-col justify-start pt-2 gap-3 subsec border border-red-800 h-44 w-54 px-7 relative">
                            <img src={wellbeingicn} alt="card-icon" className="absolute top-2 -left-5 w-10 bg-white p-2 shadow rounded-full" />
                            <h2 className="text-lg font-semibold text-red-800">Employee well-being</h2>
                            <p className="h-40 text-xs truncate text-pretty">We prioritize the well-being of our employees. We offer wellness programs, mental health support, and other resources to help our team members thrive, both physically and emotionally.</p>
                        </div>
                    </div>
                    <div className='w-full'>
                        <div class="flex flex-col justify-start pt-2 gap-3 subsec border border-red-800 h-44 w-54 px-7 relative">
                            <img src={funicn} alt="card-icon" className="absolute top-2 -left-5 w-10 bg-white p-2 shadow rounded-full" />
                            <h2 className="text-lg font-semibold text-red-800">Fun and flexibility</h2>
                            <p className="h-40 text-xs truncate text-pretty">Work should be enjoyable! We inject a sense of fun into our daily routines through team-building activities, casual dress codes, and a relaxed office atmosphere.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-9/12 py-10" id="life">
                <h2 className="text-left w-full text-2xl font-semibold py-10">Life At Expertlancing</h2>
                <video autoplay="{true}" loop muted controls className="w-full h-full object-cover" src={meetinggif} alt="" />
                <p className="w-full text-base py-5 text-justify  xs:max-sm:text-sm">
                Life at Expertlancing is a vibrant blend of professionalism and camaraderie. Employee engagement is at the heart of our culture, with regular team-building activities, interactive workshops, and open forums where every voice is heard and valued. We believe in celebrating achievements, big and small, whether it’s through festive gatherings, milestone celebrations, or casual Friday events. Our workplace fosters a sense of belonging, encouraging creativity, and collaboration. We prioritize learning and development, offering programs that empower our team to grow professionally. Wellness is a key focus, with initiatives supporting both physical and mental health, ensuring our employees are happy, healthy, and motivated. We work hard,  celebrate often, and always strive to create an environment where everyone feels appreciated and motivated to excel.
                </p>
            </div>
            <div className=" flex flex-col items-center justify-center py-10 xs:max-sm:gap-5">
                <div className="flex w-full xs:max-sm:w-10/12">
                    <div className="flex flex-col items-center justify-center h-56 w-80 bg-white p-10 shadow rounded xs:max-sm:w-40 xs:max-sm:h-40 xs:max-sm:p-0">
                        <img className="w-8/12 xs:max-sm:10/12" src={glassdoorimg} alt="" />
                    </div>
                    <div className="bg-gray-50 h-32 w-8/12 p-4 rounded-r shadow xs:max-sm:h-40">
                        <h2 className="text-xl font-semibold xs:max-sm:text-sm">Why should you consider working with us?</h2>
                        <p className="text-sm">Checkout our Glassdoor profile.</p>
                        <button className="bg-glassdoorgreen opacity-80 text-white px-2 py-1 text-sm mt-5" onClick={()=> window.open("https://www.glassdoor.co.in/Overview/Working-at-ExpertLancing-EI_IE1991737.11,24.htm", "_blank")}>Click Here!</button>
                    </div>
                </div>
                <div className="flex w-full xs:max-sm:w-10/12">
                    <div className="bg-gray-50 h-32 w-8/12 self-end p-4 rounded-r shadow xs:max-sm:h-40">
                        <p className="text-sm">We are</p>
                        <h2 className="text-xl font-semibold text-red-800 xs:max-sm:text-sm">Great Place to work <span><i class="fa fa-registered text-xs" aria-hidden="true"></i></span> Certified <span><i class="fa fa-trademark text-xs" aria-hidden="true"></i></span></h2>
                        <button className="bg-greatplaceblue opacity-80 text-white px-2 py-1 text-sm mt-5" onClick={()=> window.open("https://www.linkedin.com/company/expertlancing-ip-services/", "_blank")}>Click Here!</button>
                    </div>
                    <div className="flex flex-col items-center justify-center h-56 w-80 bg-white p-10 shadow rounded xs:max-sm:w-40 xs:max-sm:h-40 xs:max-sm:p-0">
                        <img className="w-5/12" src={greatplace} alt="" />
                    </div>
                </div>
            </div>
            <div className="w-9/12 py-10" id="meet">
                <div className="flex items-center justify-between">
                    <h2 className="text-left w-full text-2xl font-semibold py-10 xs:max-sm:text-[1.2em]">Meet Our People</h2>
                    <Link className="bg-red-800 px-5 py-2 text-white xs:max-sm:text-sm" to={'/ourpeople'}>Explore</Link>
                </div>
                <video autoplay="{true}" controls loop muted className="w-full h-72 object-cover" src={ourpeoplegif} alt="" />
            </div>
            <div className="p-16 xs:max-sm:w-11/12 xs:max-sm:p-5" id="openings">
              <h2 className="text-2xl w-full text-center font-semibold pb-10 text-red-800 xs:max-sm:text-[1.2em]">
                If there’s room for growth, there’s room for you.
              </h2>
              <div className="flex items-start justify-center gap-16 w-full xs:max-sm:flex-col xs:max-sm:gap-10">
                <div className="flex flex-col items-start justify-center gap-5 w-4/12 pt-5 xs:max-sm:w-full">
                  <h2 className="text-2xl font-semibold xs:max-sm:text-[1.2em]">Current Openings</h2>
                  <p className="text-base xs:max-sm:text-sm xs:max-sm:text-justify">
                    Explore exciting career opportunities with us! We're looking for talented, passionate individuals to join our dynamic team. If you're driven by innovation, eager to make an impact, and ready to grow with a company that values creativity and collaboration, we want to hear from you. Check out our open positions and find your perfect fit. Your next career move starts here!
                  </p>
                  <Link to={'/aboutus'} className="bg-red-800 text-white px-5 py-2">About Us</Link>
                </div>
                <div className="w-8/12 xs:max-sm:w-full">
                  {['business-development', 'operations', 'market-research'].map((category) => (
                    <div key={category}>
                      <div
                        className={`dropdown-label flex items-center justify-between gap-5 border-b border-b-red-800 p-5 cursor-pointer ${openDropdown === category ? 'rotate-icon' : ''}`}
                        onClick={() => toggleDropdown(category)}
                      >
                        <h2 className="text-xl font-semibold">{category.replace('-', ' ').toUpperCase()}</h2>
                        <i className={`fa fa-caret-down transition-transform duration-300 ${openDropdown === category ? 'rotate-180' : ''}`}></i>
                      </div>
                      {openDropdown === category && (
                        <div className="dropdown-div h-40 overflow-hidden overflow-y-auto shadow-inner grid grid-cols-3 auto-rows-auto gap-5 p-5 xs:max-sm:grid-cols-1">
                          {/* Dropdown items */}
                          {jobs[category]?.map((job, index) => (
                            <div key={index} className="flex flex-col justify-between gap-2 shadow w-56 p-5 rounded">
                              <p className="text-xs text-gray-400">{job.category}</p>
                              <h2 className="text-xl font-semibold">{job.title}</h2>
                              <hr />
                              <div className="flex items-center justify-between">
                                <p className="text-xs text-gray-400">{job.experience}</p>
                                <div className="flex justify-center items-center gap-2">
                                  <Link to={'mailto:careers@expertlancing.com'}
                                    className="text-xs bg-red-800 py-1 px-2 rounded text-white"
                                    // onClick={() => handleApplyClick(job)}
                                  >
                                    Apply
                                  </Link>
                                  <button onClick={() => downloadPdf(job.pdfUrl)}><i className="fa fa-arrow-down text-xs bg-red-800 py-1 px-2 rounded text-white"></i></button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              
              {/* Application Form */}
              {/* {selectedJob && (
                <div className="fixed top-14 inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                  <div className="bg-white p-8 rounded shadow-lg w-1/3">
                    <h2 className="text-2xl font-semibold mb-4">Apply for {selectedJob.title}</h2>
                    <form onSubmit={handleSubmit}>
                      <div className="mb-4">
                          <label className="block text-sm font-medium text-gray-700">Name</label>
                          <input
                              type="text"
                              name="name"
                              value={formData.name}
                              onChange={handleInputChange}
                              className="mt-1 block w-full border border-gray-300 rounded shadow-sm"
                              required
                          />
                      </div>
                      <div className="mb-4">
                          <label className="block text-sm font-medium text-gray-700">Email</label>
                          <input
                              type="email"
                              name="email"
                              value={formData.email}
                              onChange={handleInputChange}
                              className="mt-1 block w-full border border-gray-300 rounded shadow-sm"
                              required
                          />
                      </div>
                      <div className="mb-4">
                          <label className="block text-sm font-medium text-gray-700 pb-1">Tell us about yourself:</label>
                          <textarea
                              name="message"
                              value={formData.message}
                              rows={5}
                              className="w-full border rounded text-sm"
                              maxLength={200}
                              onChange={handleInputChange}
                              required
                          ></textarea>
                      </div>
                      <div className="flex justify-end">
                          <button
                              type="button"
                              className="bg-red-800 text-white px-4 py-2 rounded"
                              onClick={handleCloseForm}
                          >
                              Close
                          </button>
                          <button
                              type="submit"
                              className="ml-4 bg-blue-600 text-white px-4 py-2 rounded"
                          >
                              Submit
                          </button>
                      </div>
                    </form>
                  </div>
                </div>
              )} */}
            </div>
            <News />
        </section>
    );
};

export default Career;