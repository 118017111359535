import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Sol = ({ artData }) => {
  const dataItems = [
    { id: 1, caseLink:`/${artData[5].title.replaceAll(' ', '-')}`, solLink:`/IP-Consulting-Services` , caseStudy: `${artData[5].title}`, solution:'IP Consulting Services', image:'/solution_img/IP_consulting_main.png'},
    { id: 2, caseLink:`/${artData[3].title.replaceAll(' ', '-')}`, solLink:`/Strategic-Market-Insights` , caseStudy: `${artData[3].title}`, solution:'Strategic Market Insights', image:'/solution_img/strat_market_main.jpg'},
    { id: 3, caseLink:`/${artData[2].title.replaceAll(' ', '-')}`, solLink:`/Competitive-&-Consumer-Intelligence` , caseStudy: `${artData[2].title}`, solution:'Competitive & Consumer Intelligence', image:'/solution_img/compet_consum_intel_main.jpg'},
    { id: 4, caseLink:`/${artData[4].title.replaceAll(' ', '-')}`, solLink:`/Innovation-&-Technology-Strategy` , caseStudy: `${artData[4].title}`, solution:'Innovation & Technology Strategy', image:'/solution_img/innov_tech_main.jpg'},
    { id: 5, caseLink:`/${artData[25].title.replaceAll(' ', '-')}`, solLink:`/Growth-&-Market-Expansion` , caseStudy: `${artData[25].title}`, solution:'Growth & Market Expansion', image:'/solution_img/growth_market_exp_main.jpg'},
    { id: 6, caseLink:`/${artData[41].title.replaceAll(' ', '-')}`, solLink:`/Operational-Excellence-&-Benchmarking` , caseStudy: `${artData[41].title}`, solution:'Operational Excellence & Benchmarking', image:'/solution_img/oper_excel_main.jpg'},
    { id: 7, caseLink:`/${artData[24].title.replaceAll(' ', '-')}`, solLink:`/Emerging-Opportunities-&-Ecosystem Mapping` , caseStudy: `${artData[24].title}`, solution:'Emerging Opportunities & Ecosystem Mapping', image:'/solution_img/emerg_opport_main.jpg'},
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % dataItems.length);
        setFade(true);
      }, 500);
    }, 8000);

    return() =>  clearInterval(interval);
  });

  const handleClick = (index) => {
    setFade(false);
    setTimeout(() => {
      setCurrentIndex(index);
      setFade(true);
    }, 500);
  };

  return (
    <section className="flex flex-col items-center w-full px-20 py-10 xs:max-sm:px-0">
        <div className='flex items-start justify-center gap-10'>
            <div className='w-7/12 min-h-96 flex flex-col justify-start gap-20 xs:max-sm:min-h-fit xs:max-sm:w-screen xs:max-sm:gap-10 xs:max-sm:items-center'>
                <div className='xs:max-sm:w-9/12 xs:max-sm:flex xs:max-sm:flex-col xs:max-sm:items-start'>
                    <h2 className='text-3xl font-semibold xs:max-sm:text-[1.2em]'>Experts At Work</h2>
                    <p className='text-base xs:max-sm:text-xs xs:max-sm:w-full xs:max-sm:text-justify'>What matters to us is earning the trust of our clients worldwide through the best of research and Expert solutions. Read ahead for the complete story</p>
                </div>
              <div className='flex items-start justify-center gap-10 xs:max-sm:gap-5 xs:max-sm:w-9/12'>
                <div className={`flex flex-col justify-center gap-5 w-80 h-auto transition-opacity duration-500 ${fade ? 'opacity-100' : 'opacity-0'}`}>
                    <p className='text-sm xs:max-sm:text-xxs font-semibold text-gray-400'>Featured client success story</p>
                    <hr/>
                    <h2 className='text-2xl xs:max-sm:text-sm'>{dataItems[currentIndex].caseStudy}</h2>
                    <Link className='text-red-800 text-sm xs:max-sm:text-xs' to={dataItems[currentIndex].caseLink}>Read More <i className='fa-solid fa-caret-right'></i></Link>
                </div>
                <div className={`flex flex-col justify-center gap-5 w-80 h-auto transition-opacity duration-500 ${fade ? 'opacity-100' : 'opacity-0'}`}>
                    <p className='text-sm xs:max-sm:text-xxs font-semibold text-gray-400'>How we helped</p>
                    <hr/>
                    <h2 className='text-2xl xs:max-sm:text-sm'>{dataItems[currentIndex].solution}</h2>
                    <Link className='text-red-800 text-sm xs:max-sm:text-xs' to={dataItems[currentIndex].solLink}>View Solution <i className='fa-solid fa-caret-right'></i></Link>
                </div>
              </div>
            </div>
            <div className='xs:max-sm:hidden w-5/12 flex flex-col items-end gap-5'>
                <img className={` w-80 h-96 object-cover transition-opacity duration-500 ${fade ? 'opacity-100' : 'opacity-0'}`} src={dataItems[currentIndex].image} alt='' />
                <div className="flex space-x-4 mt-4">
                  {dataItems.map((item, index) => (
                    <button key={item.id} onClick={() => handleClick(index)} className={`text-white rounded-full w-4 h-2 transition-colors duration-200 ${currentIndex === index ? 'bg-red-800' : 'bg-gray-100'} hover:bg-red-800`}>
                    </button>
                  ))}
                </div>
            </div>
        </div>
    </section>
  );
};

export default Sol;

