import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import News from '../components/newsletter';
import vector from "../assets/Vector.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import fetchAndReadExcel from '../components/fetchAndReadExcel';

const SolutionsContent = (data) => {
  const [activeNav, setActiveNav] = useState('overview');
  const [insights, setInsights] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const jsonData = await fetchAndReadExcel();
        setInsights(jsonData['data3']);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const sections = ['overview', 'sub-solutions', 'literature', 'client'];
      let currentSection = '';

      sections.forEach((section) => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
            currentSection = section;
          }
        }
      });

      if (currentSection) {
        setActiveNav(currentSection);
      }
    };

    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const services = data.data;
  document.title = `${services.Name}`;
  const iconUrl = `/solution_img/${data.data.icon}`;
  const sub_array = Object.keys(services);
  const Sub_category1 = [];

  for (let i = 0; i < sub_array.length; i++) {
    if (sub_array[i].includes('Card') && !(sub_array[i].includes('_content') || sub_array[i].includes('_image'))) {
      let path = '/' + services[sub_array[i]];
      Sub_category1.push((
        <div className='w-full' key={i}>
          <div className="flex flex-col items-center justify-start pt-2 gap-3 subsec border border-red-800 h-64 w-full relative xs:max-sm:pt-0 xs:max-sm:rounded xs:max-sm:overflow-hidden xs:max-sm:gap-0 xs:max-sm:h-full xs:max-sm:pb-2">
            <img src={iconUrl} alt="card-icon" className="absolute pl-[.5rem] top-2 -left-5 w-10 bg-white p-2 shadow rounded-full xs:max-sm:relative xs:max-sm:top-0 xs:max-sm:left-0 xs:max-sm:p-1 xs:max-sm:rounded-none xs:max-sm:bg-transparent xs:max-sm:shadow-none xs:max-sm:pl-[.25rem]" />
            <h2 className="text-sm font-semibold text-red-800 px-7 xs:max-sm:px-2 xs:max-sm:text-white xs:max-sm:bg-red-800 xs:max-sm:py-2">{services[sub_array[i]]}</h2>
            <p className="h-40 overflow-hidden text-xs px-7 xs:max-sm:px-2  xs:max-sm:py-2 xs:max-sm:text-[12px] xs:max-sm:overflow-hidden text-pretty">{services[sub_array[i].replace('header', 'content')]}</p>
          </div>
        </div>
      ));
    }
  }

  let case_study = [];
  for (let [key, value] of Object.entries(insights)) {
    if (services.Name in value && 'Case Study' === value.insight_type && case_study.length < 3) {
      case_study.push(value);
    }
  }

  let case_div = [];
  let case_div1 = [];
  if (case_study.length !== 0) {
    case_div.push((
      <div className='bg-gray-100 p-10 xs:max-sm:hidden' key={case_study[0].title}>
        <div>
          <h2 className='text-2xl font-semibold' dangerouslySetInnerHTML={{ __html: case_study[0].title }}></h2>
          <p className='text-sm pt-5' dangerouslySetInnerHTML={{ __html: case_study[0].Para_1 }}></p>
        </div>
        <div className='grid grid-cols-2 auto-rows-auto gap-10 w-full py-10'>
          <img src={`/insights_img/${case_study[0].main_image_all}`} alt='' />
          <span className='text-sm text-justify' dangerouslySetInnerHTML={{ __html: case_study[0].Para_2 }}></span>
          <span className='col-span-full w-full columns-2 text-sm text-justify' dangerouslySetInnerHTML={{ __html: case_study[0].Para_3 }}></span>
        </div>
        <hr />
        <div className='flex items-center justify-between pt-10'>
          <Link to="/insights" className='text-gray-900 bg-white rounded px-8 py-2 shadow hover:text-red-800'>Explore Insights</Link>
          <Link to={`/${case_study[0].title.replaceAll(' ', '-')}`} className='flex items-center justify-center gap-5 text-red-800'>Read Full Article Here<i className='fa fa-chevron-right' aria-hidden='true'></i></Link>
        </div>
      </div>
    ));
  }

  if (case_study.length > 1) {
    case_div1.push((
      <div className='w-full flex items-center justify-center gap-10 pt-10 xs:max-sm:flex-col xs:max-sm:gap-5 xs:max-sm:pt-0' key="case_div1">
        <Link to={`/${case_study[0].title.replaceAll(' ', '-')}`} className='hidden case-card w-full xs:max-sm:block'>
          <div className="absolute top-0 p-8 text-white z-30 xs:max-sm:p-4 xs:max-sm:w-full">
            <h2 className="text-2xl font-semibold xs:max-sm:text-[1em]">{case_study[0].title}</h2>
            <p className="h-28 overflow-ellipsis overflow-hidden text-sm w-full text-justify pt-5 xs:max-sm:pt-2 xs:max-sm:text-[10px] xs:max-sm:truncate xs:max-sm:h-fit">{case_study[0].Para_1}</p>
            <button type="button" class="absolute top-4 text-white border-white right-4 border rounded p-1 text-xxs z-50 xs:max-sm:bg-white xs:max-sm:font-semibold xs:max-sm:text-gray-500 xs:max-sm:top-[100%] xs:max-sm:right-[73%] xs:max-sm:w-[56px]">Case Study</button>
          </div>
          <Link to={`/${case_study[0].title.replaceAll(' ', '-')}`} className='flex items-center justify-center gap-5 absolute bottom-5 right-5 text-white text-xs z-30 xs:max-sm:bottom-4 xs:max-sm:right-4 xs:max-sm:gap-2'>Read More <i className='fa fa-chevron-right'></i></Link>
          <div className='absolute case-overlay bg-black opacity-50 h-full w-full z-20'></div>
          <img className='w-full h-full object-cover' src={`/insights_img/${case_study[0].main_image_all}`} alt="" />
        </Link>
        <Link to={`/${case_study[1].title.replaceAll(' ', '-')}`} className='case-card w-6/12 xs:max-sm:w-full'>
          <div className="absolute top-0 p-8 text-white z-30 xs:max-sm:p-4 xs:max-sm:w-full">
            <h2 className="text-2xl font-semibold xs:max-sm:text-[1em]">{case_study[1].title}</h2>
            <p className="h-28 overflow-ellipsis overflow-hidden text-sm w-full text-justify pt-5 xs:max-sm:pt-2 xs:max-sm:text-[10px] xs:max-sm:truncate xs:max-sm:h-fit">{case_study[1].Para_1}</p>
            <button type="button" class="absolute top-4 text-white border-white right-4 border rounded p-1 text-xxs z-50 xs:max-sm:bg-white xs:max-sm:font-semibold xs:max-sm:text-gray-500 xs:max-sm:top-[100%] xs:max-sm:right-[73%] xs:max-sm:w-[56px]">Case Study</button>
          </div>
          <Link to={`/${case_study[1].title.replaceAll(' ', '-')}`} className='flex items-center justify-center gap-5 absolute bottom-5 right-5 text-white text-xs z-30 xs:max-sm:bottom-4 xs:max-sm:right-4 xs:max-sm:gap-2'>Read More <i className='fa fa-chevron-right'></i></Link>
          <div className='absolute case-overlay bg-black opacity-50 h-full w-full z-20'></div>
          <img className='w-full h-full object-cover' src={`/insights_img/${case_study[1].main_image_all}`} alt="" />
        </Link>
        <Link to={`/${case_study[2].title.replaceAll(' ', '-')}`} className='case-card w-6/12 xs:max-sm:w-full'>
          <div className="absolute top-0 p-8 text-white z-30 xs:max-sm:p-4 xs:max-sm:w-full">
            <h2 className="text-2xl font-semibold xs:max-sm:text-[1em]">{case_study[2].title}</h2>
            <p className="h-28 overflow-ellipsis overflow-hidden text-sm w-full text-justify pt-5 xs:max-sm:pt-2 xs:max-sm:text-[10px] xs:max-sm:truncate xs:max-sm:h-fit">{case_study[2].Para_1}</p>
            <button type="button" class="absolute top-4 text-white border-white right-4 border rounded p-1 text-xxs z-50 xs:max-sm:bg-white xs:max-sm:font-semibold xs:max-sm:text-gray-500 xs:max-sm:top-[100%] xs:max-sm:right-[73%] xs:max-sm:w-[56px]">Case Study</button>
          </div>
          <Link to={`/${case_study[2].title.replaceAll(' ', '-')}`} className='flex items-center justify-center gap-5 absolute bottom-5 right-5 text-white text-xs z-30 xs:max-sm:bottom-4 xs:max-sm:right-4 xs:max-sm:gap-2'>Read More <i className='fa fa-chevron-right'></i></Link>
          <div className='absolute case-overlay bg-black opacity-50 h-full w-full z-20'></div>
          <img className='w-full h-full object-cover' src={`/insights_img/${case_study[2].main_image_all}`} alt="" />
        </Link>
      </div>
    ));
  }

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 800,
    cssEase: "linear"
  };

  return (
    <section className='flex flex-col items-center justify-center'>
      <Helmet>
      <title>IP Consulting Services</title>
      <meta name='description' content='Elevating Intellectual Property to Secure and Sustain Market Leadership.' />
      </Helmet>
      <div className='ind-banner overflow-hidden h-96 w-full relative'>
        <img className='brightness-50 h-full w-full object-cover' src={`/solution_img/${data.data.banner}`} alt="" />
        <div className="absolute top-0 flex flex-col w-1/2 justify-center items-start p-8 xs:max-sm:w-full">
          <div className="tracking-widest text-[3em] font-semibold text-[white] uppercase xs:max-sm:text-[2em]">
            <h2>{services.Name}</h2>
          </div>
          <div className="text-sm xs:max-sm:text-[14px] text-white">
            <p>{services.Tagline}</p>
          </div>
        </div>
      </div>
      <div className='sticky top-14 bg-white z-50 w-full shadow-sm xs:max-sm:hidden'>
        <ul className='in-nav-active innernav flex items-center justify-center gap-28 w-full py-5 xs:max-sm:gap-0 xs:max-sm:text-sm xs:max-sm:justify-around'>
          <a href="#overview">
            <li className={activeNav === 'overview' ? 'active-inner' : ''} onClick={() => setActiveNav('overview')}>Overview</li>
          </a>
          <a href="#sub-solutions">
            <li className={activeNav === 'sub-solutions' ? 'active-inner' : ''} onClick={() => setActiveNav('sub-solutions')}>Sub Solutions</li>
          </a>
          <a href="#literature">
            <li className={activeNav === 'literature' ? 'active-inner' : ''} onClick={() => setActiveNav('literature')}>Literature</li>
          </a>
          <a href="#client">
            <li className={activeNav === 'client' ? 'active-inner' : ''} onClick={() => setActiveNav('client')}>Client</li>
          </a>
        </ul>
      </div>
      <div className='flex flex-col items-center justify-center gap-10 w-10/12 py-12' id="overview">
        <p className='text-2xl font-semibold xs:max-sm:text-[1.2em]'>
          {services.Main_quote}
        </p>
        <p className='text-base text-justify xs:max-sm:text-sm'>
          {services.Overview}
        </p>
      </div>
      <div id="sub-solutions" className='w-10/12 py-16'>
        <h2 className='text-2xl font-semibold pb-5 xs:max-sm:text-[1.2em]'>Our Services</h2>
        <div className="grid grid-cols-4 auto-rows-auto gap-10 w-full xs:max-sm:grid-cols-2 xs:max-sm:gap-3">
          {Sub_category1}
        </div>
      </div>
      <div id='literature' className='w-10/12'>
        {case_div}
        {case_div1}
      </div>
      <div id="client" className="testimonies relative w-7/12 h-40 my-10 xs:max-sm:w-10/12">
        <h2 className="text-xl font-semibold text-center xs:max-sm:text-[1.2em]">Actions Speak Louder Than Words</h2>
        <img className="absolute bottom-0 right-0" src={vector} alt="" />
        <Slider {...settings} className='py-10'>
          <div className='!flex flex-col items-start'>
            <p className="text-sm">{services.Testimonial_content1}</p>
            <span className="w-full text-xs text-red-800">~{services.Leader_name1}</span>
          </div>
          <div className='!flex flex-col items-start'>
            <p className="text-sm">{services.Testimonial_content2}</p>
            <span className="w-full text-xs text-red-800">~{services.Leader_name2}</span>
          </div>
          <div className='!flex flex-col items-start'>
            <p className="text-sm">{services.Testimonial_content3}</p>
            <span className="w-full text-xs text-red-800">~{services.Leader_name3}</span>
          </div>
        </Slider>
      </div>
      <News />
    </section>
  );
}

export default SolutionsContent;
