import React, {useState, useEffect} from "react";
import News from '../components/newsletter';
import "./Sustainability.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import vector from "../assets/Vector.png";
import { Helmet } from 'react-helmet-async';

const Sustainability = () => {
    const serviceContent = {
        "Sustainability and Circular Economy": {
            drive: [
                "Growing environmental concerns about climate change, plastic pollution, and resource depletion.",
                "Stricter regulations from governments promoting circular economy models and reducing emissions.",
                "Rising consumer demand for eco-friendly, biodegradable, and sustainable products.",
                "Global push for reducing dependence on fossil fuels and adopting renewable feedstocks."
            ],
            impact: [
                "Increased adoption of bio-based materials and renewable feedstocks in chemical production.",
                "Development of advanced chemical recycling technologies to reduce plastic waste.",
                "Circular economy principles integrated into production, reducing resource use and waste.",
                "Growth in sustainable product offerings and greener chemical processes."
            ]
        },
        "Digitalization and Smart Manufacturing": {
            drive: [
                "Industry 4.0 technologies, including AI, IoT, and machine learning, transforming manufacturing.",
                "Pressure to improve operational efficiency and reduce energy consumption.",
                "Increasing need for flexible, real-time production to meet market and customer demands.",
                "Demand for greater supply chain transparency and traceability."
            ],
            impact: [
                "AI and data analytics optimizing chemical reactions, lowering costs and waste.",
                "Predictive maintenance using IoT sensors, reducing downtime and improving asset lifespan.",
                "Smart manufacturing enabling customization and faster adaptation to product changes.",
                "Blockchain and digital platforms improving transparency in the supply chain."
            ]
        },
        "Decarbonization and Energy Transition": {
            drive: [
                "Growing pressure to reduce carbon emissions in line with climate change targets.",
                "Regulatory demands for companies to adopt low-carbon technologies and processes.",
                "Investor and shareholder pressure to demonstrate environmental responsibility (ESG).",
                "Transition from fossil fuels to renewable energy sources like solar, wind, and hydrogen."
            ],
            impact: [
                "Increased investment in green hydrogen and carbon capture technologies.",
                "Electrification of chemical processes, reducing dependency on fossil fuels.",
                "Companies reducing their carbon footprint and aligning with net-zero emission targets.",
                "Development of sustainable chemicals and processes powered by renewable energy."
            ]
        },
        "Innovation in Advanced Materials": {
            drive: [
                "Demand for high-performance materials in industries like automotive, aerospace, and electronics.",
                "Need for lightweight, durable, and energy-efficient materials in sustainable technologies.",
                "Advances in nanotechnology, enabling new properties and applications for chemicals.",
                "Growing requirements for environmentally friendly materials across multiple industries."
            ],
            impact: [
                "Development of nanomaterials and smart materials with unique properties for diverse applications.",
                "Lightweight composites replacing traditional materials, improving fuel efficiency in transportation.",
                "Innovation in energy storage materials, enabling advancements in electric vehicles and renewable energy.",
                "Expansion of advanced materials used in healthcare, electronics, and sustainability-focused sectors."
            ]
        },
        "Health, Wellness, and Safety": {
            drive: [
                "Increased global health awareness due to the COVID-19 pandemic.",
                "Rising demand for safer, non-toxic chemicals in personal care, cosmetics, and healthcare.",
                "Stricter regulations on harmful chemicals, requiring safer, biobased alternatives.",
                "Consumer preferences for natural, health-focused, and transparent product formulations."
            ],
            impact: [
                "Surge in demand for biobased and biodegradable chemicals in consumer goods.",
                "Growth in antimicrobial coatings and materials for healthcare and public spaces.",
                "Enhanced product safety and transparency, with companies disclosing environmental impacts.",
                "Safer, cleaner formulations for personal care and household products, aligned with consumer expectations."
            ]
        }
    };

    const serviceKeys = Object.keys(serviceContent);
    const [driveContent, setDriveContent] = useState([]);
    const [impactContent, setImpactContent] = useState([]);
    const [activeService, setActiveService] = useState('');

    useEffect(() => {
        if (serviceKeys.length > 0) {
            const defaultService = serviceKeys[0];
            setActiveService(defaultService);
            setDriveContent(serviceContent[defaultService].drive);
            setImpactContent(serviceContent[defaultService].impact);
        }
    }, []);

    const handleClick = (service) => {
        setDriveContent(serviceContent[service].drive);
        setImpactContent(serviceContent[service].impact);
        setActiveService(service);
    };

    const [activeOption, setActiveOption] = useState(null);
    const [rotation, setRotation] = useState(0);
    const [fade, setFade] = useState(false);

    const handleOptionClick = (option) => {
        setActiveOption(option);
        setRotation((prevRotation) => prevRotation + 90);
        setFade(true);
        setTimeout(() => setFade(false), 200); 
    };

    const cardData = {
        option1: [
            { icon: '1.', title: 'Innovation Protection:', description: 'We help in strengthen your sustainability innovations by prior art searches for your patents for eco-friendly technologies, materials, and processes.' },
            { icon: '2.', title: 'Green Technology Patents:', description: 'Our team tracks advancements in sustainability-related patents, ensuring your R&D aligns with global trends like renewable energy, water treatment, and waste reduction.' },
            { icon: '3.', title: 'Patent Landscaping & Gap Analysis:', description: 'Through in-depth patent landscaping, we identify opportunities for sustainable innovation and help you stay ahead of competitors by uncovering market gaps.' },
        ],
        option2: [
            { icon: '1.', title: 'Sustainable Market Opportunities:', description: 'We provide actionable insights into emerging sustainability trends, helping you access new markets driven by consumer demand for eco-friendly products and services.' },
            { icon: '2.', title: 'Regulatory Compliance & Standards:', description: 'We analyze the evolving regulatory landscape for sustainability, ensuring your technologies and products meet international environmental standards and guidelines.' },
            { icon: '3.', title: 'Competitor Benchmarking:', description: 'We benchmark your sustainability initiatives against industry leaders and competitors, refining your strategy to improve environmental performance while maximizing profitability.' },
        ],
        option3: [
            { icon: '1.', title: 'Environmental Impact Assessments:', description: 'We use data-driven methods to assess your environmental impact across operations and product lifecycles, providing strategies to reduce carbon footprints and resource consumption.' },
            { icon: '2.', title: 'Sustainability Reporting Support:', description: 'Our team helps develop comprehensive sustainability reports, aligning with ESG (Environmental, Social, and Governance) frameworks to ensure transparent and impactful communication with stakeholders.' },
        ],
        option4: [
            { icon: '1.', title: 'Sustainability Roadmaps:', description: 'We help you develop long-term sustainability roadmaps that integrate patent strategies, market insights, and innovation goals, ensuring sustainability becomes central to your business growth.' },
            { icon: '2.', title: 'Innovation & Product Development:', description: 'By combining patent research with market data, we guide your product development towards sustainable solutions that meet both regulatory demands and consumer preferences.' },
        ],
    };

    // const testimonials = [
    //     {
    //         bgImage: '/solution_img/growth_market_exp_main.jpg',
    //         author: 'CTO, Chemical Industry',
    //         text: '"ExpertLancing\'s deep understanding of intellectual property helped us secure vital patents for our new green technology. Their expertise in patent strategy not only protected our innovations but also opened up licensing opportunities that we hadn\'t considered. Their team is always professional and proactive in finding solutions."',
    //     },
    //     {
    //         bgImage: '/solution_img/strat_market_main.jpg',
    //         author: 'VP of Strategy, Energy Solutions Company',
    //         text: '“Thanks to ExpertLancing, we now have a clear understanding of the competitive landscape in the energy sector. Their market insights and actionable intelligence enabled us to refine our strategy, which resulted in a significant market share increase. Their data-driven approach is truly impressive."',
    //     },
    //     {
    //         bgImage: '/solution_img/emerg_opport_main.jpg',
    //         author: 'Chief Innovation Officer, Sustainable Packaging Company',
    //         text: '"The ExpertLancing team played a key role in guiding our R&D efforts towards sustainable innovation. Their in-depth patent research and technology roadmaps were instrumental in helping us develop cutting-edge, eco-friendly products that meet regulatory standards and consumer expectations."',
    //     },
    //     {
    //         bgImage: '/solution_img/emerg_opport_main.jpg',
    //         author: 'CEO, Start-up Player',
    //         text: '"With ExpertLancing \'s help, we successfully entered new international markets. Their strategic advice, combined with precise market research, provided us with a clear roadmap for growth. The results exceeded our expectations, and our business has expanded more than we anticipated."',
    //     },
    //     {
    //         bgImage: '/solution_img/emerg_opport_main.jpg',
    //         author: 'Managing Director, Semiconductor Company',
    //         text: '"ExpertLancing identified several emerging opportunities in the water treatment sector that we had overlooked. Their ecosystem mapping allowed us to pursue collaborations with key industry players, giving us a stronger foothold in the market."',
    //     },
    // ];

    // const [currentIndex, setCurrentIndex] = useState(0);

    // const handleNext = () => {
    //     setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    // };

    // const handlePrevious = () => {
    //     setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
    // };

    // const { bgImage, author, text } = testimonials[currentIndex];

    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
        speed: 800,
        cssEase: "linear"
    };

    return(
        <section className="flex flex-col items-center">
            <Helmet>
            <title>Sustainability</title>
            <meta name='description' content='Building a Sustainable Future: Our Commitment to Eco-Friendly Solutions and Community Impact.' />
            </Helmet>
            <div className="ind-banner relative bg-slate-950 text-white h-96 overflow-hidden w-full">
                <img class='brightness-50 h-full w-full object-cover' src={`/sustainability_imgs/exploring-role-futuristic-robotics-advanced-urban-agricultural-greenhouses.jpg`} alt="" />
                <div class="absolute z-40 top-2 left-2 xs:max-sm:left-0 xs:max-sm:px-5 flex flex-col w-8/12 xs:max-sm:w-full justify-center items-start p-8">
                    <div className="tracking-widest text-[3em] font-semibold text-[white] uppercase xs:max-sm:text-[1.8em]">
                        <h2>Sustainability</h2>
                    </div>
                    <div className="text-sm xs:max-sm:text-sm w-fit">
                        <p>
                        Building a Sustainable Future: Our Commitment to Eco-Friendly Solutions and Community Impact
                        </p>
                    </div>
                </div>
            </div>
            <p className="w-10/12 pt-16 xs:max-sm:text-sm">
                At ExpertLancing, we believe that sustainability is key to long-term success. Our team of cross-industry experts in 
                technology and business consultancy, help our clients develop strategies that not only drive growth but also foster 
                environmental stewardship. Through data-driven analytics and insights, we ensure that sustainability becomes an integral 
                part of innovation, compliance, and market leadership. We offer end-to-end support from patent protection to strategic 
                sustainability planning, driving positive environmental and business outcomes. 
            </p>
            <div className="w-10/12 pt-16">
                <h2 className="text-2xl font-semibold pb-10 xs:max-sm:text-[1.2em]">Market Drivers & Impact to Industries</h2>
                <div className="min-h-screen grid grid-cols-3 grid-rows-auto gap-10 xs:max-sm:flex xs:max-sm:flex-col xs:max-sm:min-h-fit">
                    <div className="p-5 min-h-screen max-h-screen bg-gray-100">
                        <p className="text-[16px] py-5 xs:max-sm:text-sm">
                            Key market drivers such as sustainability trends, technological advancements, regulatory changes, and shifting consumer 
                            preferences are reshaping industries across the board. Companies are increasingly adopting eco-friendly practices and 
                            innovative technologies to meet environmental standards and reduce carbon footprints.
                        </p>
                        <ul>
                            {serviceKeys.map(service => (
                                <a href="#drive" key={service} className={`hidden p-2 my-2 text-center text-sm bg-gray-200 cursor-pointer xs:max-sm:block ${activeService === service ? 'active-sustainability' : ''}`} onClick={() => handleClick(service)}>
                                    {service}
                                </a>
                            ))}
                            {serviceKeys.map(service => (
                                <li key={service} className={`p-2 my-2 text-center text-sm bg-gray-200 cursor-pointer xs:max-sm:hidden ${activeService === service ? 'active-sustainability' : ''}`} onClick={() => handleClick(service)}>
                                    {service}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="min-h-full bg-gray-100" id="drive">
                        <div className="flex flex-col items-center justify-center w-full gap-5 py-5 bg-gradient-to-b from-red-700 to-amber-950">
                            <img className="w-16" src={`/sustainability_imgs/target.png`} alt="" />
                            <h2 className="text-white text-center text-2xl font-semibold">The Drive</h2>
                        </div>
                        <ul className="list-disc p-5 flex flex-col items-center justify-center gap-5">
                            {driveContent.map((item, index) => (
                                <li key={index} className="text-sm text-justify">{item}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="min-h-full bg-gray-100">
                        {/* <div className="flex flex-col items-center justify-center gap-5 py-5 bg-gradient-to-b from-gray-50 to-gray-400"> */}
                        <div className="flex flex-col items-center justify-center gap-5 py-5 bg-gradient-to-b from-green-400 to-sustainability-green">
                            <img className="w-16" src={`/sustainability_imgs/world_10215550.png`} alt="" />
                            <h2 className="text-center text-2xl font-semibold text-white">The Impact</h2>
                        </div>
                        <ul className="list-disc p-5 flex flex-col items-center justify-center gap-5">
                            {impactContent.map((item, index) => (
                                <li key={index} className="text-sm text-justify">{item}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="pt-16 flex flex-col items-center">
                <div className="w-10/12">
                    <h2 className="text-2xl font-semibold pb-10 xs:max-sm:text-[1.2em]">ExpertLancing’s Integrated Service Solutions: Driving Sustainability as a Key Business Lever</h2>
                    <p className="text-[16px] xs:max-sm:text-sm">
                        At ExpertLancing, we believe that sustainability is not just a compliance requirement but a critical 
                        driver of innovation, competitive advantage, and long-term market leadership. Through our integrated 
                        service solutions, we work with you to embed sustainability into the core of your business strategy—delivering 
                        both environmental and business benefits.
                    </p>
                </div>
                <div className="w-full flex flex-col items-center justify-center gap-5 py-10 xs:max-sm:w-10/12">
                    <div className="w-6/12 h-fit flex flex-col items-center justify-center gap-5 relative xs:max-sm:w-full">
                        <div className="flex items-start justify-center gap-5">
                            <a href="#sus_drivers" onClick={() => handleOptionClick('option1')} className="img_options flex flex-col items-center justify-center gap-1 cursor-pointer">
                                <img className={`w-16 h-16 rounded-full p-2 bg-sustainability-green hover:bg-green-900 xs:max-sm:w-12 xs:max-sm:h-12 ${activeOption === 'option1' ? 'active-sus-driver' : ''}`} src={`/sustainability_imgs/icon -03.png`} alt="" />
                                <p className={`text-xs text-gray-700 font-semibold text-center xs:max-sm:text-[10px] ${activeOption === 'option1' ? 'active-sus-driver-txt' : ''}`}>Sustainability-Focused Patent Research</p>
                            </a>

                            <a href="#sus_drivers" onClick={() => handleOptionClick('option2')} className="img_options flex flex-col items-center justify-center gap-1 cursor-pointer">
                                <img className={`w-16 h-16 rounded-full p-2 bg-sustainability-green hover:bg-green-900 xs:max-sm:w-12 xs:max-sm:h-12 ${activeOption === 'option2' ? 'active-sus-driver' : ''}`} src={`/sustainability_imgs/icon-04.png`} alt="" />
                                <p className={`text-xs text-gray-700 font-semibold text-center xs:max-sm:text-[10px] ${activeOption === 'option2' ? 'active-sus-driver-txt' : ''}`}>Market Research & Competitive Intelligence</p>
                            </a>

                            <a href="#sus_drivers" onClick={() => handleOptionClick('option3')} className="img_options flex flex-col items-center justify-center gap-1 cursor-pointer">
                                <img className={`w-16 h-16 rounded-full p-2 bg-sustainability-green hover:bg-green-900 xs:max-sm:w-12 xs:max-sm:h-12 ${activeOption === 'option3' ? 'active-sus-driver' : ''}`} src={`/sustainability_imgs/icon-06.png`} alt="" />
                                <p className={`text-xs text-gray-700 font-semibold text-center xs:max-sm:text-[10px] ${activeOption === 'option3' ? 'active-sus-driver-txt' : ''}`}>ESG and Sustainability Analytics</p>
                            </a>

                            <a href="#sus_drivers" onClick={() => handleOptionClick('option4')} className="img_options flex flex-col items-center justify-center gap-1 cursor-pointer">
                                <img className={`w-16 h-16 rounded-full p-2 bg-sustainability-green hover:bg-green-900 xs:max-sm:w-12 xs:max-sm:h-12 ${activeOption === 'option4' ? 'active-sus-driver' : ''}`} src={`/sustainability_imgs/icon-07.png`} alt="" />
                                <p className={`text-xs text-gray-700 font-semibold text-center xs:max-sm:text-[10px] ${activeOption === 'option4' ? 'active-sus-driver-txt' : ''}`}>Strategic Sustainability Planning</p>
                            </a>
                        </div>
                        <div className="relative w-1/2 xs:max-sm:w-8/12">
                            <img className="title_img1 absolute top-0 z-40" src={`/sustainability_imgs/Mask circle 1.png`} alt="" />
                            <img className={`title_img2 z-10 transition-transform duration-500`} style={{ transform: `rotate(${rotation}deg)` }} src={`/sustainability_imgs/circle.png`} alt="" />
                        </div>
                    </div>
                    {activeOption && (
                        <div id="sus_drivers" className={`w-6/12 grid grid-rows-auto grid-cols-3 gap-5 transition-opacity duration-500 xs:max-sm:w-full xs:max-sm:grid-cols-2  ${fade ? 'opacity-0' : 'opacity-100'}`}>
                            {cardData[activeOption].map((card, index) => (
                                <div key={index} className="bg-white rounded shadow">
                                    <div className="">
                                        <span className="text-sm font-semibold text-sustainability-green p-2 mr-2">{card.icon}</span>
                                        <div>
                                            <h3 className="bg-sustainability-green text-white p-2 font-bold">{card.title}</h3>
                                            <p className="text-sm p-2 text-gray-600">{card.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div> 

            </div>
            <div className="w-10/12 h-[70vh] pt-16 flex items-start justify-center gap-10 xs:max-sm:flex-col xs:max-sm:h-fit">
                <div className="flex flex-col items-start justify-center gap-5 w-4/12 xs:max-sm:w-full">
                    <h2 className="text-2xl font-semibold xs:max-sm:text-[1.2em]">Setting a Distinctive Path to Sustainability</h2>
                    <p className="text-sm font-semibold">Holistic Sustainability Support from End-to-End</p>
                    <p className="text-sm text-justify">
                        By working with ExpertLancing, sustainability becomes an essential driver of your business transformation—fueling 
                        innovation, ensuring compliance, and building market leadership. Our integrated approach supports you in not only 
                        meeting but exceeding your sustainability goals, leading to positive environmental outcomes and sustained business growth.
                    </p>
                </div>
                <div className="w-4/12 bg-gray-100 p-5 min-h-full flex flex-col items-start justify-start gap-10 xs:max-sm:w-full">
                    <p className="text-lg font-semibold">Integrated Service Model</p>
                    <p className="text-sm text-justify">
                        From patent protection and innovation strategy to market intelligence and compliance monitoring, ExpertLancing’s integrated 
                        services provide you with a one-stop solution for all your sustainability needs. We ensure that your business not only meets 
                        the demands of today’s environmental challenges but also thrives as a market leader in the future.
                    </p>
                </div>
                <div className="w-4/12 bg-gray-100 p-5 min-h-full flex flex-col items-start justify-start gap-10 xs:max-sm:w-full">
                    <p className="text-lg font-semibold">Customized Solutions</p>
                    <p className="text-sm text-justify">
                        Every business has unique sustainability goals, and we tailor our research, analytics, and strategic advice to meet your specific 
                        requirements. Whether you're aiming for carbon neutrality, waste reduction, or eco-friendly innovation, we design solutions that 
                        fit your objectives.
                    </p>
                </div>
            </div>
            <div className="pt-16 w-full flex flex-col items-center">
                {/* <div className="flex w-10/12 pb-10 items-start justify-between">
                    <h2 className="text-2xl font-semibold">Our Commitment Towards<br/> Sustainability</h2>
                    <p className="text-sm w-4/12">At ExpertLancing, we believe that sustainability is not just a compliance requirement </p>
                </div> */}
                {/* <div className="relative">
                    <img className="testimonial-bg w-screen h-screen object-cover" src={bgImage} alt="" />
                    <div className="test-bg bg-white rounded">
                        <div className="w-6/12 grid grid-rows-2 grid-cols-2 gap-5 p-10">
                            <div>
                                <hr />
                                <p>Some Energy</p>
                                <i className="fa-solid fa-times"></i>
                                <Link>view details</Link>
                            </div>
                            <div>
                                <hr />
                                <p>Some Energy</p>
                                <i className="fa-solid fa-times"></i>
                                <Link>view details</Link>
                            </div>
                            <div>
                                <hr />
                                <p>Some Energy</p>
                                <i className="fa-solid fa-times"></i>
                                <Link>view details</Link>
                            </div>
                            <div>
                                <hr />
                                <p>Some Energy</p>
                                <i className="fa-solid fa-times"></i>
                                <Link>view details</Link>
                            </div>
                        </div>
                        <div className="relative w-6/12 text-sm">
                            <div className="flex flex-col items-start justify-center gap-5 absolute top-[52%] right-12">
                                <h2 className="author font-semibold">{author}</h2>
                                <p className="testimonial text-justify">{text}</p>
                            </div>
                        </div>
                    </div>
                    <button onClick={handlePrevious} className="absolute left-4 bottom-5 transform -translate-y-1/2 text-white text-sm p-2 rounded flex items-center justify-center gap-2"><i className="fa fa-chevron-left text-xxs"></i>Previous</button>
                    <button onClick={handleNext} className="absolute right-4 bottom-5 transform -translate-y-1/2 p-2 rounded text-white text-sm flex items-center justify-center gap-2">Next<i className="fa fa-chevron-right text-xxs"></i></button>
                </div> */}
                <div id="client" className="testimonies relative w-7/12 h-40 my-10 xs:max-sm:w-10/12 xs:max-sm:h-fit">
                    <h2 className="text-xl font-semibold text-center xs:max-sm:text-[1.2em]">Our Commitment Towards Sustainability</h2>
                    <img className="absolute bottom-0 right-0" src={vector} alt="" />
                    <Slider {...settings} className='py-10'>
                        <div className='!flex flex-col items-start'>
                            <p className="text-sm">"ExpertLancing's deep understanding of intellectual property helped us secure vital patents for our new green technology. Their expertise in patent strategy not only protected our innovations but also opened up licensing opportunities that we hadn't considered. Their team is always professional and proactive in finding solutions."</p>
                            <span className="w-full text-xs text-red-800">~CTO, Chemical Industry</span>
                        </div>
                        <div className='!flex flex-col items-start'>
                            <p className="text-sm">“Thanks to ExpertLancing, we now have a clear understanding of the competitive landscape in the energy sector. Their market insights and actionable intelligence enabled us to refine our strategy, which resulted in a significant market share increase. Their data-driven approach is truly impressive."</p>
                            <span className="w-full text-xs text-red-800">~VP of Strategy, Energy Solutions Company</span>
                        </div>
                        <div className='!flex flex-col items-start'>
                            <p className="text-sm">"The ExpertLancing team played a key role in guiding our R&D efforts towards sustainable innovation. Their in-depth patent research and technology roadmaps were instrumental in helping us develop cutting-edge, eco-friendly products that meet regulatory standards and consumer expectations."</p>
                            <span className="w-full text-xs text-red-800">~Chief Innovation Officer, Sustainable Packaging Company</span>
                        </div>
                        <div className='!flex flex-col items-start'>
                            <p className="text-sm">"With ExpertLancing's help, we successfully entered new international markets. Their strategic advice, combined with precise market research, provided us with a clear roadmap for growth. The results exceeded our expectations, and our business has expanded more than we anticipated."</p>
                            <span className="w-full text-xs text-red-800">~CEO, Start-up Player</span>
                        </div>
                        <div className='!flex flex-col items-start'>
                            <p className="text-sm">"ExpertLancing identified several emerging opportunities in the water treatment sector that we had overlooked. Their ecosystem mapping allowed us to pursue collaborations with key industry players, giving us a stronger foothold in the market."</p>
                            <span className="w-full text-xs text-red-800">~Managing Director, Semiconductor Company</span>
                        </div>
                    </Slider>
                </div>
            </div>
            <News/>
        </section>
    )
}

export default Sustainability