import { useEffect, useState } from 'react';
import fetchAndReadExcel from './components/fetchAndReadExcel';

export const useFetchData = () => {
  const [industry, setIndustry] = useState([]);
  const [services, setServices] = useState([]);
  const [insights, setInsights] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const jsonData = await fetchAndReadExcel();
        setIndustry(jsonData['data1']);
        setServices(jsonData['data2']);
        setInsights(jsonData['data3']);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  return { industry, services, insights, loading, error };
};
