import React, { useState, useEffect } from "react";
import './subInsight.css';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useMediaQuery from '../components/useMediaQuery';
import News from "../components/newsletter";
import fetchAndReadExcel from '../components/fetchAndReadExcel';
import { format } from 'date-fns';

import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterShareButton,
    XIcon,
} from 'react-share';
// import Insights from "./Insights";
// const isSmallScreen = useMediaQuery('(max-width: 600px)');


const InsightSubpage = ({ data }) => {

    // Slider settings
    const isSmallScreen = useMediaQuery('(max-width: 600px)');

    // Slider settings
    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "50px",
        slidesToShow: isSmallScreen ? 1 : 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        cssEase: "linear"
    };


    const [insights, setInsights] = useState([]);
    const [insights_ccc, setinsights_ccc] = useState({});
    const [images, setImages] = useState([]);
    const insightsData = data;
    document.title = `${insightsData.title}`;
    const shareUrl = `${"expertlancing.com/" + insightsData.title.replaceAll(' ', '-')}`;
    const insighttitle = `${insightsData.title}`;
    useEffect(() => {
        const getData = async () => {
            const jsonData = await fetchAndReadExcel();
            let data = jsonData['data3'];

            // Sort the data by date (assuming the date field is named 'date' and is in ISO format)
            data = data.sort((a, b) => new Date(b.Submitted_on) - new Date(a.Submitted_on));

            setInsights(data);
        };
        getData();
    }, []);
    useEffect(() => {
        const processedData = {};
        const imageMap = {};
        for (let i = 1; i <= insightsData.NoofParagraphs; i++) {
            let headData = '', paraData = '';
            if (insightsData[`head_${i}`]) {
                headData = insightsData[`head_${i}`];
            }
            if (insightsData[`Para_${i}`]) {
                paraData = insightsData[`Para_${i}`];
            }
            if (insightsData[`image_${i}`]) {
                imageMap[insightsData[`image_${i}_loc`]] = insightsData[`image_${i}`];
            }
            processedData[i] = [headData, paraData];
        }
        setinsights_ccc(processedData);
        setImages(imageMap);
    }, [insightsData]);

    const industry_name = ['Chemicals', 'Food and Nutrition', 'Energy and Natural Resources', 'Home and Personal Care', 'Healthcare and Life Sciences', 'Mobility', 'Industrials', 'Technology Media and Telecom']
    const selected_name = [];
    for (let i = 0; i < industry_name.length; i++) {
        if (Object.keys(insightsData).includes(industry_name[i])) {
            selected_name.push(industry_name[i]);
        }
    }
    const insightslider = []
    for (let i = 0; i < insights.length; i++) {
        for (let j = 0; j < selected_name.length; j++) {
            if (insights[i][selected_name[j]] != undefined && insights[i][selected_name[j]] == 'y' && insights[i]['title'] != insighttitle) {
                insightslider.push((
                    <Link className="cardcont" to={`/${insights[i].title.replaceAll(' ', '-')}`}>
                        <div className='slidecard'>
                            <img src={`/insights_img/${insights[i].main_image_all}`} alt={insights[i].title} />
                            <div className="slidecard-txt h-full">
                                <span className="slidecard-tag">{insights[i].insight_type}</span>&nbsp;&nbsp;&nbsp;
                                <span className="slidecard-tag">
                                    {format(new Date(ExcelDateToJSDate(insights[i].Submitted_on).toLocaleDateString()), 'MMMM dd, yyyy')}
                                </span>
                                <h3>{insights[i].title}</h3>
                                <p className="truncate" dangerouslySetInnerHTML={{ __html: insights[i].Para_1 }}></p>
                                <Link to={`/${insights[i].title.replaceAll(' ', '-')}`} className='absolute bottom-5 right-2 w-full text-right flex items-center justify-end gap-5 xs:max-sm:gap-2 text-white text-xs z-30'>
                                    Read More <i className='fa fa-chevron-right'></i>
                                </Link>
                            </div>
                        </div>
                    </Link>
                ))
                break;
            }
        }
    }
    // console.log(selected_name);
    // Add images to insights_ccc
    for (let [key, value] of Object.entries(images)) {
        const index = key.slice(0, -1);
        if (insights_ccc[index]) {
            insights_ccc[index].push(value);
            insights_ccc[index].push(key.slice(-1));
        }
    }

    const sub_data = [];
    for (let [key, value] of Object.entries(insights_ccc)) {
        const iconUrl = `/insights_img/${value[2]}`;
        const imgClass = `my-4 head-img-${value[3]}`;
        sub_data.push((
            <div key={key} className="hyperlink w-9/12 my-4">
                <h3 className="text-2xl font-bold" dangerouslySetInnerHTML={{ __html: value[0] }}></h3>
                <div>
                    {value[2] && (
                        <img src={iconUrl} alt="" className={imgClass} />
                    )}
                    <p className="text-[14px] py-2 text-justify whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html: value[1] }}></p>
                </div>
            </div>
        ));
    }

    function ExcelDateToJSDate(serial) {
        const utc_days = Math.floor(serial - 25569);
        const utc_value = utc_days * 86400;
        const date_info = new Date(utc_value * 1000);

        const fractional_day = serial - Math.floor(serial) + 0.0000001;
        let total_seconds = Math.floor(86400 * fractional_day);
        const seconds = total_seconds % 60;
        total_seconds -= seconds;
        const hours = Math.floor(total_seconds / (60 * 60));
        const minutes = Math.floor(total_seconds / 60) % 60;

        return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
    }

    // Convert and format the date
    let articleDate = ExcelDateToJSDate(insightsData.Submitted_on);
    let formattedDate = format(new Date(articleDate.toLocaleDateString()), 'MMMM dd, yyyy');

    const imagePath = `/insights_img/${insightsData.main_image_all}`;

    const handleCopyLink = () => {
        navigator.clipboard.writeText(shareUrl)
            .then(() => {
                alert("Link copied to clipboard!");
            })
            .catch((err) => {
                console.error("Failed to copy: ", err);
            });
    };

    let expert_pick = [];
    if (insightsData['Expert_Corner'] == 'Y') {
        expert_pick.push((
            <p className='text-white text-sm xs:max-sm:text-[14px] border border-white my-3 px-2 rounded w-fit'>Expert's Pick</p>
        ))
    }

    return (
        <section className="flex flex-col items-center">

            <div className='h-96 w-full relative'>
                <div className="absolute left-10 top-10 z-20 flex flex-col h-auto w-9/12">
                    <h2 className="text-white text-3xl font-semibold xs:max-sm:text-[1.2em]">{insightsData.title}</h2>
                    <div className="flex items-center gap-5">
                        <p className='text-white text-sm xs:max-sm:text-[14px] border border-white my-3 px-2 rounded w-fit'>{insightsData.insight_type}</p>
                        {expert_pick}
                    </div>
                </div>
                <div className="absolute bottom-5 left-10 z-10 flex items-center justify-between w-[93%] xs:max-sm:flex-col-reverse xs:max-sm:items-start xs:max-sm:gap-5">
                    <div className="flex flex-row gap-8 xs:max-sm:gap-0">
                        <div className="flex flex-col border-solid border-gray-100 border-l-2 px-2 w-2/6">
                            <p className="text-white text-xxs">Written by</p>
                            <p className="flex items-center text-sm"><span className="text-white text-[14px] xs:max-sm:text-[10px]">{insightsData.Prepared_By}</span>
                            </p>
                        </div>

                        <div className="flex flex-col border-solid border-gray-100 border-l-2 px-2 w-2/6">
                            <p className="text-white text-xxs">Published on</p>
                            <p className="flex items-center text-sm"><span className="text-white text-[14px] xs:max-sm:text-[10px]">{formattedDate}</span>
                            </p>
                        </div>

                        <div className="flex flex-col border-solid border-gray-100 border-l-2 px-2 w-2/6">
                            <p className="text-white text-xxs">Reading Time</p>
                            <p className="flex items-center text-sm"><span className="text-white text-[14px] xs:max-sm:text-[10px]">{insightsData.Read_time} Minutes</span>
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-row gap-4 z-10">
                        <div className="Demo__some-network">
                            <FacebookShareButton url={shareUrl} className="Demo__some-network__share-button">
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                        </div>
                        <div className="Demo__some-network">
                            <TwitterShareButton
                                url={shareUrl}
                                title={insighttitle}
                                className="Demo__some-network__share-button"
                            >
                                <XIcon size={32} round />
                            </TwitterShareButton>
                        </div>

                        <div className="Demo__some-network">
                            <LinkedinShareButton url={shareUrl}
                                title={insighttitle} className="Demo__some-network__share-button">
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                        </div>

                        {/* New Copy Link Button */}
                        <div className="Demo__some-network">
                            <button
                                onClick={handleCopyLink}
                                className="Demo__some-network__share-button  bg-gray-500 px-2 py-1 rounded-full"
                            >
                                <span className="text-white"><i class="fa-regular fa-copy"></i></span>
                            </button>
                        </div>
                    </div>
                </div>
                <img className='brightness-50 h-full w-full object-cover' src={imagePath} alt="Insight Banner" />
            </div>

            <p className="text-[1.2em] italic pt-5 text-left w-9/12">{insightsData.Abstract}</p>

            {/* Dynamically render the content */}
            {sub_data}
            
            <h3 className='text-2xl xs:max-sm:text-[1.2em] font-semibold my-10'>
                Related Articles
            </h3>
            <div className="slider-container w-11/12 h-full xs:max-sm:w-screen">
                <Slider {...settings}>
                    {insightslider}
                </Slider>
            </div>
            <News />
        </section>
    );
}

export default InsightSubpage;
