import React from 'react';
import './App.css';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';
import { useFetchData } from './DataFetcher';
import { createRoutes } from './Routes';

function App() {
  const { industry, services, insights, loading, error } = useFetchData();

  if (loading) return <p className='opacity-0'>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const router = createRoutes(industry, services, insights);
  
  const helmetContext = {};

  return (
    <HelmetProvider context={helmetContext}>
    <RouterProvider router={router} />
    </HelmetProvider>
  );
}

export default App;
