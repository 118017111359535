import React, { useState } from "react";
import emailjs from "emailjs-com";

const News = () => {
    const [formData, setFormData] = useState({ industry: '', subemail: '', formType: 'newsletter' });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior

        emailjs.send('service_h1hhb55', 'template_yw1i0id', formData, 'l6vPLqmeRyWSqE6LN')
            .then((result) => {
                console.log('Email successfully sent:', result.text);
                alert('Your subscription has been submitted!');
                setFormData({ industry: '', subemail: '', formType: 'newsletter' }); // Clear form data
            }, (error) => {
                console.log('Email sending error:', error.text);
                alert('There was an error sending your subscription. Please try again.');
            });
    };

    return (
        <form onSubmit={handleSubmit} className="newsletter flex flex-row items-center justify-center gap-10 border border-solid border-black outline-none p-8 m-8 xs:max-sm:flex-col xs:max-sm:gap-5">
            <div className="flex flex-col gap-6 w-6/12 xs:max-sm:w-full xs:max-sm:gap-2">
                <h2 className="text-2xl font-medium pl-2">Want to know more?</h2>
                <label htmlFor="ind" className="text-xl pl-3 xs:max-sm:text-lg">I am interested in:</label>
                <select
                    name="industry"
                    id="ind"
                    className="text-xl cursor-pointer font-semibold border-b border-red-800 p-2 outline-none xs:max-sm:text-sm"
                    value={formData.industry}
                    onChange={handleChange}
                >
                    <option value="">Select an industry</option>
                    <option value="Chemicals">Chemicals</option>
                    <option value="Food & Nutrition">Food & Nutrition</option>
                    <option value="Energy and Natural Resources">Energy and Natural Resources</option>
                    <option value="Home & Personal Care">Home & Personal Care</option>
                    <option value="Healthcare and Life Sciences">Healthcare and Life Sciences</option>
                    <option value="Mobility">Mobility</option>
                    <option value="Industrials">Industrials</option>
                    <option value="Technology, Media and Telecom">Technology, Media and Telecom</option>
                </select>
            </div>
            <div className="flex flex-col gap-3 w-6/12 xs:max-sm:w-full xs:max-sm:gap-2">
                <p className="text-sm">Get access to your premier source for staying informed on global developments and the latest technological advancements across industries.</p>
                <input
                    className="border border-black p-2 bg-slate-50 outline-none"
                    id="news_email"
                    type="email"
                    name="subemail"
                    placeholder="Your Email"
                    value={formData.subemail}
                    onChange={handleChange}
                    required
                />
                <button type="submit" className="w-4/12 bg-red-800 text-white py-2 xs:max-sm:w-full xs:max-sm:text-sm">Subscribe</button>
            </div>
        </form>
    );
};

export default News;
