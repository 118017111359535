import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Nav.css'
import fetchAndReadExcel from './fetchAndReadExcel';

const Navbar = () => {
  // For Industries 

  const [isdropdownvisible, setdropdown] = useState(false)
  const [hoveredId, setHoveredId] = useState('chemical');
  const [serviceId, setServiceId] = useState('IP Consulting Services');
  const [dropdownvisible, setsoldropdown] = useState()
  const [isCompanyDropdownVisible, setCompanyDropdownVisible] = useState(false);
  const [insights, setInsights] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('Solutions');
  const [activeItem, setActiveItem] = useState('Solutions');
  const timeoutRef = useRef(null);
  const navBodyRef = useRef(null);
  const menuCheckboxRef = useRef(null);

  const showdropdown = () => {
    setdropdown(true);
  }
  const hidedropdown = () => {
    setdropdown(false);
  }
  useEffect(() => {
    const getData = async () => {
      try {
        const jsonData = await fetchAndReadExcel();
        setInsights(jsonData['data3']);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Hide menu if click is outside the entire header
      if (navBodyRef.current && !navBodyRef.current.contains(event.target)) {
        setIsOpen(false); // Close the menu
        setSelectedItem(null); // Hide the dropdown content
        if (menuCheckboxRef.current) {
          menuCheckboxRef.current.checked = false; // Uncheck the checkbox
        }
      }
    };
    
    document.addEventListener('click', handleClickOutside);
    
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  const dropdownstyle = {
    display: isdropdownvisible ? 'flex' : 'none'
  }

  const handleMouseEnter = (id) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setHoveredId(id);
  };

  const handleMouseLeave = () => {
    // timeoutRef.current = setTimeout(() => {
    //     setHoveredId('chemical'); // Default item
    // }, 200);
  };

  const handleSerMouseEnter = (id) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setServiceId(id);
  };

  const handleSerMouseLeave = () => {
    // timeoutRef.current = setTimeout(() => {
    //   setServiceId('IP Consulting Services'); // Default item
    // }, 200);
  };

  const solshowdropdown = () => {
    setsoldropdown(true);
  }
  const solhidedropdown = () => {
    setsoldropdown(false);
  }

  const soldropdownstyle = {
    display: dropdownvisible ? 'flex' : 'none'
  }

  // For Company
  const showCompanyDropdown = () => setCompanyDropdownVisible(true);
  const hideCompanyDropdown = () => setCompanyDropdownVisible(false);
  const companyDropdownStyle = {
    display: isCompanyDropdownVisible ? 'flex' : 'none'
  };


  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
    if (isOpen) {
      setSelectedItem(null);
      setActiveItem(null);
    }
  };

  const handleItemClick = (item) => {
    // If the clicked item is already active, collapse the menu
    if (selectedItem === item) {
      setSelectedItem(null);
      setActiveItem(null);
    } else {
      setSelectedItem(item);  // Show the corresponding nav-body
      setActiveItem(item);     // Set active class for the clicked item
    }
  };

  const contentData = {
    Solutions: {
        links: [
            { text: 'IP Consulting Services', to: '/IP-Consulting-Services' },
            { text: 'Strategic Market Insights', to: '/Strategic-Market-Insights' },
            { text: 'Competitive & Consumer Intelligence', to: '/Competitive-&-Consumer-Intelligence' },
            { text: 'Innovation & Technology Strategy', to: '/Innovation-&-Technology-Strategy' },
            { text: 'Growth & Market Expansion', to: '/Growth-&-Market-Expansion' },
            { text: 'Operational Excellence & Benchmarking', to: '/Operational-Excellence-&-Benchmarking' },
            { text: 'Emerging Opportunities & Ecosystem Mapping', to: '/Emerging-Opportunities-&-Ecosystem-Mapping' },
        ],
    },

    Industries: {
        links: [
            { text: 'Chemicals', to: '/Chemicals' },
            { text: 'Food & Nutrition', to: '/Food-and-Nutrition' },
            { text: 'Energy and Natural Resources', to: '/Energy-and-Natural-Resources' },
            { text: 'Home & Personal Care', to: '/Home-and-Personal-Care' },
            { text: 'Healthcare and Life Sciences', to: '/Healthcare-and-Life-Sciences' },
            { text: 'Mobility', to: '/Mobility' },
            { text: 'Industrials', to: '/Industrials' },
            { text: 'Technology, Media and Telecom', to: '/Technology-Media-and-Telecom' },
        ],
    },
    Company: {
        links: [
            { text: 'Insights', to: '/insights' },
            { text: 'Elabs', to: '/labs' },
            { text: 'Sustainability', to: '/sustainability' },
            { text: 'About Us', to: '/aboutus' },
            { text: 'Our People', to: '/ourpeople' },
            { text: 'Contact Us', to: '/contact' },
            { text: 'Careers', to: '/career' },
        ],
    },
};

const renderExclusiveContent = () => {
    const { links } = contentData[selectedItem];

    return (
        <div className='flex flex-col items-start justify-center gap-4'>
            {links.map((link, index) => (
                <Link key={index} to={link.to} className="text-white text-sm flex items-center justify-between w-full">
                    {link.text}
                    <i className='fa fa-long-arrow-right'></i>
                </Link>
            ))}
        </div>
    );
};


  return (
    <div className='top-0 sticky self-start z-[100]'>
      <header className="relative header z-50  bg-white shadow-md flex items-center justify-between h-14 px-5" ref={navBodyRef}>
        <Link className="w-2/12 xs:max-sm:w-6/12" to="/">
            <img className='w-full' src="expertlancing.png" alt="" />
        </Link>

        
        <label className="hidden flex-col gap-1 w-4 h-3 xs:max-sm:flex cursor-pointer">
            <input className="peer hidden" type="checkbox" onChange={toggleMenu} ref={menuCheckboxRef} />
            <div className="rounded-2xl h-[2px] w-1/3 bg-black duration-500 peer-checked:rotate-[225deg] origin-right peer-checked:-translate-x-[3px] peer-checked:bg-red-800"></div>
            <div className="rounded-2xl h-[2px] w-full bg-black duration-500 peer-checked:-rotate-45 peer-checked:bg-red-800"></div>
            <div className="rounded-2xl h-[2px] w-1/3 bg-black duration-500 place-self-end peer-checked:rotate-[225deg] origin-left peer-checked:translate-x-[3px] peer-checked:bg-red-800"></div>
        </label>
        <ul className={`resp-nav ${isOpen ? 'flex' : 'hidden'} z-40 items-center justify-around absolute top-[56px] left-0 bg-white w-screen h-[60px] text-[13px] shadow-md border-b-2`}>
            <li className={`cursor-pointer ${activeItem === 'Solutions'&& selectedItem === 'Solutions' ? 'text-red-800 font-semibold underline underline-offset-[23px]' : ''}`} onClick={() => handleItemClick('Solutions')}>Solutions</li>
            <li className={`cursor-pointer ${activeItem === 'Industries' && selectedItem === 'Industries' ? 'text-red-800 font-semibold underline underline-offset-[23px]' : ''}`} onClick={() => handleItemClick('Industries')}>Industries</li>
            <li className={`cursor-pointer ${activeItem === 'Company' && selectedItem === 'Company' ? 'text-red-800 font-semibold underline underline-offset-[23px]' : ''}`} onClick={() => handleItemClick('Company')}>Company</li>
            {selectedItem && (
                <div className="nav-body absolute top-[59px] z-30 left-0 bg-blue-950 text-white w-full p-4 shadow-md">
                    {renderExclusiveContent()}
                </div>
            )}
        </ul>


        <nav className="nav font-medium text-base xs:max-sm:hidden">
          <ul className="flex items-center">
            <li id='solutions' className="solutions p-4 border-b-2 border-blue-800 border-opacity-0 hover:border-opacity-100 hover:text-blue-500 duration-200 cursor-pointer active"
              onMouseOver={solshowdropdown}
              onMouseOut={solhidedropdown}
            >
              <Link to={'/IP-Consulting-Services'} className='cursor-pointer'>Solutions</Link>
              <div id='indusbar' style={soldropdownstyle} className='solution-bar absolute bg-white'>
                <div className='subIndustries grow bg-slate-900 p-8'>
                  <ul className='flex flex-col gap-2'>
                    <Link to='/IP-Consulting-Services'>
                      <li className='p-2 hover:bg-blue-900' id='IP Consulting Services' onMouseEnter={() => handleSerMouseEnter('IP Consulting Services')} onMouseLeave={handleSerMouseLeave}>
                        IP Consulting Services
                        <i className="fa-solid fa-arrow-right"></i>
                      </li>
                    </Link>
                    <Link to='/Strategic-Market-Insights'>
                      <li className='p-2 hover:bg-blue-900' id='Strategic Market Insights' onMouseEnter={() => handleSerMouseEnter('Strategic Market Insights')} onMouseLeave={handleSerMouseLeave}>
                        Strategic Market Insights
                        <i className="fa-solid fa-arrow-right"></i>
                      </li>
                    </Link>
                    <Link to='/Competitive-&-Consumer-Intelligence'>
                      <li className='p-2 hover:bg-blue-900' id='Competitive & Consumer Intelligence' onMouseEnter={() => handleSerMouseEnter('Competitive & Consumer Intelligence')} onMouseLeave={handleSerMouseLeave}>
                        Competitive & Consumer Intelligence
                        <i className="fa-solid fa-arrow-right"></i>
                      </li>
                    </Link>
                    <Link to='/Innovation-&-Technology-Strategy'>
                      <li className='p-2 hover:bg-blue-900' id='Innovation & Technology Strategy' onMouseEnter={() => handleSerMouseEnter('Innovation & Technology Strategy')} onMouseLeave={handleSerMouseLeave}>
                        Innovation & Technology Strategy
                        <i className="fa-solid fa-arrow-right"></i>
                      </li>
                    </Link>
                    <Link to='/Growth-&-Market-Expansion'>
                      <li className='p-2 hover:bg-blue-900' id='Growth & Market Expansion' onMouseEnter={() => handleSerMouseEnter('Growth & Market Expansion')} onMouseLeave={handleSerMouseLeave}>
                        Growth & Market Expansion
                        <i className="fa-solid fa-arrow-right"></i>
                      </li>
                    </Link>
                    <Link to='/Operational-Excellence-&-Benchmarking'>
                      <li className='p-2 hover:bg-blue-900' id='Operational Excellence & Benchmarking' onMouseEnter={() => handleSerMouseEnter('Operational Excellence & Benchmarking')} onMouseLeave={handleSerMouseLeave}>
                        Operational Excellence & Benchmarking
                        <i className="fa-solid fa-arrow-right"></i>
                      </li>
                    </Link>
                    <Link to='/Emerging-Opportunities-&-Ecosystem-Mapping'>
                      <li className='p-2 hover:bg-blue-900' id='Emerging Opportunities & Ecosystem Mapping' onMouseEnter={() => handleSerMouseEnter('Emerging Opportunities & Ecosystem Mapping')} onMouseLeave={handleSerMouseLeave}>
                        Emerging Opportunities & Ecosystem Mapping
                        <i className="fa-solid fa-arrow-right"></i>
                      </li>
                    </Link>
                  </ul>
                </div>
                <div className="sub-info flex justify-center items-center px-10 grow text-center bg-blue-950">
                  {serviceId === 'IP Consulting Services' && (
                    <>
                      <p >Elevating Intellectual Property to Secure and Sustain Market Leadership.</p>
                    </>
                  )}
                  {serviceId === 'Strategic Market Insights' && (
                    <>
                      <p>Delivering Strategic Intelligence for Unrivaled Market Positioning and Growth.</p>
                    </>
                  )}
                  {serviceId === 'Competitive & Consumer Intelligence' && (
                    <>
                      <p >Empowering Your Strategy with Insight-Driven Competitive and Consumer Mastery.</p>
                    </>
                  )}
                  {serviceId === 'Innovation & Technology Strategy' && (
                    <>
                      <p>Pioneering the Future with Advanced, Impactful Technology Roadmaps.</p>
                    </>
                  )}
                  {serviceId === 'Growth & Market Expansion' && (
                    <>
                      <p >Driving Strategic Growth with Precision and Market-Centric Insights.</p>
                    </>
                  )}
                  {serviceId === 'Operational Excellence & Benchmarking' && (
                    <>
                      <p>Achieving Operational Excellence through Industry-Leading Benchmarking and Analysis.</p>
                    </>
                  )}
                  {serviceId === 'Emerging Opportunities & Ecosystem Mapping' && (
                    <>
                      <p>Navigating New Market Frontiers with Strategic Ecosystem and Opportunity Mapping.</p>
                    </>
                  )}
                </div>
                <div className="arts-section grow bg-slate-900 flex flex-col gap-4 p-5">
                  {serviceId === 'IP Consulting Services' && (
                    <>
                      <div className='article-image w-full h-4/6'>
                        <img src={`/insights_img/${insights[5].main_image_all}`} alt="" className='w-full h-full object-cover' />
                      </div>
                      <div className="article-title flex flex-col gap-3 text-white">
                        <span className='text-xs'>{insights[5].insights_type}</span>
                        <span>{insights[5].title}</span>
                        <Link to={`/${insights[5].title.replaceAll(' ', '-')}`} className='text-xs text-right'>Read full Insight <i className="fa-solid fa-angle-right"></i></Link>
                      </div>
                    </>
                  )}
                  {serviceId === 'Strategic Market Insights' && (
                    <>
                      <div className='article-image w-full h-4/6'>
                        <img src={`/insights_img/${insights[16].main_image_all}`} alt="" className='w-full h-full object-cover' />
                      </div>
                      <div className="article-title flex flex-col gap-3 text-white">
                        <span className='text-xs'>{insights[16].insights_type}</span>
                        <span>{insights[16].title}</span>
                        <Link to={`/${insights[16].title.replaceAll(' ', '-')}`} className='text-xs text-right'>Read full Insight <i className="fa-solid fa-angle-right"></i></Link>
                      </div>
                    </>
                  )}
                  {serviceId === 'Competitive & Consumer Intelligence' && (
                    <>
                      <div className='article-image w-full h-4/6'>
                        <img src={`/insights_img/${insights[15].main_image_all}`} alt="" className='w-full h-full object-cover' />
                      </div>
                      <div className="article-title flex flex-col gap-3 text-white">
                        <span className='text-xs'>{insights[15].insights_type}</span>
                        <span>{insights[15].title}</span>
                        <Link to={`/${insights[15].title.replaceAll(' ', '-').replaceAll(' ', '-')}`} className='text-xs text-right'>Read full Insight <i className="fa-solid fa-angle-right"></i></Link>
                      </div>
                    </>
                  )}
                  {serviceId === 'Innovation & Technology Strategy' && (
                    <>
                      <div className='article-image w-full h-4/6'>
                        <img src={`/insights_img/${insights[14].main_image_all}`} alt="" className='w-full h-full object-cover' />
                      </div>
                      <div className="article-title flex flex-col gap-3 text-white">
                        <span className='text-xs'>{insights[14].insights_type}</span>
                        <span>{insights[14].title}</span>
                        <Link to={`/${insights[14].title.replaceAll(' ', '-')}`} className='text-xs text-right'>Read full Insight <i className="fa-solid fa-angle-right"></i></Link>
                      </div>
                    </>
                  )}
                  {serviceId === 'Growth & Market Expansion' && (
                    <>
                      <div className='article-image w-full h-4/6'>
                        <img src={`/insights_img/${insights[34].main_image_all}`} alt="" className='w-full h-full object-cover' />
                      </div>
                      <div className="article-title flex flex-col gap-3 text-white">
                        <span className='text-xs'>{insights[34].insights_type}</span>
                        <span>{insights[34].title}</span>
                        <Link to={`/${insights[34].title.replaceAll(' ', '-')}`} className='text-xs text-right'>Read full Insight <i className="fa-solid fa-angle-right"></i></Link>
                      </div>
                    </>
                  )}
                  {serviceId === 'Operational Excellence & Benchmarking' && (
                    <>
                      <div className='article-image w-full h-4/6'>
                        <img src={`/insights_img/${insights[4].main_image_all}`} alt="" className='w-full h-full object-cover' />
                      </div>
                      <div className="article-title flex flex-col gap-3 text-white">
                        <span className='text-xs'>{insights[4].insights_type}</span>
                        <span>{insights[4].title}</span>
                        <Link to={`/${insights[4].title.replaceAll(' ', '-')}`} className='text-xs text-right'>Read full Insight <i className="fa-solid fa-angle-right"></i></Link>
                      </div>
                    </>
                  )}
                  {serviceId === 'Emerging Opportunities & Ecosystem Mapping' && (
                    <>
                      <div className='article-image w-full h-4/6'>
                        <img src={`/insights_img/${insights[44].main_image_all}`} alt="" className='w-full h-full object-cover' />
                      </div>
                      <div className="article-title flex flex-col gap-3 text-white">
                        <span className='text-xs'>{insights[44].insights_type}</span>
                        <span>{insights[44].title}</span>
                        <Link to={`/${insights[44].title.replaceAll(' ', '-')}`} className='text-xs text-right'>Read full Insight <i className="fa-solid fa-angle-right"></i></Link>
                      </div>
                    </>
                  )}
                </div>
              </div>

            </li>
            <li className="p-4 border-b-2 border-blue-800 border-opacity-0 hover:border-opacity-100 hover:text-blue-500 duration-200"
              onMouseOver={showdropdown}
              onMouseOut={hidedropdown}
            >
              <Link to={'/Chemicals'} className='cursor-pointer'>Industries</Link>
              <div id='indusbar' style={dropdownstyle} className='industry-bar absolute bg-white'>
                <div className='subIndustries grow bg-slate-900 px-8'>
                  <ul className='flex flex-col gap-1'>
                    <Link to='/Chemicals'>
                      <li className='p-2 hover:bg-blue-900' id='chemical' onMouseEnter={() => handleMouseEnter('chemical')} onMouseLeave={handleMouseLeave}>
                        Chemicals
                        <i className="fa-solid fa-arrow-right"></i>
                      </li>
                    </Link>
                    <Link to='/Food-and-Nutrition'>
                      <li className='p-2 hover:bg-blue-900' id='food' onMouseEnter={() => handleMouseEnter('food')} onMouseLeave={handleMouseLeave}>
                        Food & Nutrition
                        <i className="fa-solid fa-arrow-right"></i>
                      </li>
                    </Link>
                    <Link to='/Energy-and-Natural-Resources'>
                      <li className='p-2 hover:bg-blue-900' id='energy' onMouseEnter={() => handleMouseEnter('energy')} onMouseLeave={handleMouseLeave}>
                        Energy and Natural Resources
                        <i className="fa-solid fa-arrow-right"></i>
                      </li>
                    </Link>
                    <Link to='/Home-and-Personal-Care'>
                      <li className='p-2 hover:bg-blue-900' id='home' onMouseEnter={() => handleMouseEnter('home')} onMouseLeave={handleMouseLeave}>
                        Home & Personal Care
                        <i className="fa-solid fa-arrow-right"></i>
                      </li>
                    </Link>
                    <Link to='/Healthcare-and-Life-Sciences'>
                      <li className='p-2 hover:bg-blue-900' id='health' onMouseEnter={() => handleMouseEnter('health')} onMouseLeave={handleMouseLeave}>
                        Healthcare and Life Sciences
                        <i className="fa-solid fa-arrow-right"></i>
                      </li>
                    </Link>
                    <Link to='/Mobility'>
                      <li className='p-2 hover:bg-blue-900' id='mobility' onMouseEnter={() => handleMouseEnter('mobility')} onMouseLeave={handleMouseLeave}>
                        Mobility
                        <i className="fa-solid fa-arrow-right"></i>
                      </li>
                    </Link>
                    <Link to='/Industrials'>
                      <li className='p-2 hover:bg-blue-900' id='industry' onMouseEnter={() => handleMouseEnter('industry')} onMouseLeave={handleMouseLeave}>
                        Industrials
                        <i className="fa-solid fa-arrow-right"></i>
                      </li>
                    </Link>
                    <Link to='/Technology-Media-and-Telecom'>
                      <li className='p-2 hover:bg-blue-900' id='techno' onMouseEnter={() => handleMouseEnter('techno')} onMouseLeave={handleMouseLeave}>
                        Technology, Media and Telecom
                        <i className="fa-solid fa-arrow-right"></i>
                      </li>
                    </Link>
                  </ul>
                </div>
                <div className="sub-info flex justify-center items-center px-10 text-center grow bg-blue-950">
                  {hoveredId === 'chemical' && (
                    <>
                      <p id='chemicaldrop'>Pioneering a sustainable chemical revolution, redefining the future of industrial innovation and environmental stewardship</p>
                    </>
                  )}
                  {hoveredId === 'food' && (
                    <>
                      <p id='fooddrop'>Transforming global nutrition with breakthrough innovations for a healthier, more sustainable future</p>
                    </>
                  )}
                  {hoveredId === 'energy' && (
                    <>
                      <p id='energydrop'>Leading the charge toward a renewable energy future, unlocking the potential of natural resources responsibly</p>
                    </>
                  )}
                  {hoveredId === 'home' && (
                    <>
                      <p id='homedrop'>Shaping the future of home & personal care with innovative, eco-friendly solutions for a better tomorrow.</p>
                    </>
                  )}
                  {hoveredId === 'health' && (
                    <>
                      <p id='healthdrop'>Leading the charge toward a renewable energy future, unlocking the potential of natural resources responsibly</p>
                    </>
                  )}
                  {hoveredId === 'mobility' && (
                    <>
                      <p id='mobilitydrop'>Driving the future of mobility with smart, sustainable transportation solutions for a connected world</p>
                    </>
                  )}
                  {hoveredId === 'industry' && (
                    <>
                      <p id='industrydrop'>Engineering the future with advanced industrial technologies, paving the way for a sustainable and efficient global economy</p>
                    </>
                  )}
                  {hoveredId === 'techno' && (
                    <>
                      <p id='technodrop'>Forging the future of connectivity and media with transformative technologies that redefine human interaction and information access</p>
                    </>
                  )}
                </div>
                <div className="arts-section grow bg-slate-900 flex flex-col gap-4 p-5">
                  {hoveredId === 'chemical' && (
                    <>
                      <div className='article-image w-full h-4/6'>
                        <img src={`/insights_img/${insights[45].main_image_all}`} alt="" className='w-full h-full object-cover' />
                      </div>
                      <div className="article-title flex flex-col gap-3 text-white">
                        <span className='text-xs'>{insights[45].insights_type}</span>
                        <span>{insights[45].title}</span>
                        <Link to={`/${insights[45].title.replaceAll(' ', '-')}`} className='text-xs text-right'>Read full Insight <i className="fa-solid fa-angle-right"></i></Link>
                      </div>
                    </>
                  )}
                  {hoveredId === 'food' && (
                    <>
                      <div className='article-image w-full h-4/6'>
                        <img src={`/insights_img/${insights[40].main_image_all}`} alt="" className='w-full h-full object-cover' />
                      </div>
                      <div className="article-title flex flex-col gap-3 text-white">
                        <span className='text-xs'>{insights[40].insights_type}</span>
                        <span>{insights[40].title}</span>
                        <Link to={`/${insights[40].title.replaceAll(' ', '-')}`} className='text-xs text-right'>Read full Insight <i className="fa-solid fa-angle-right"></i></Link>
                      </div>
                    </>
                  )}
                  {hoveredId === 'energy' && (
                    <>
                      <div className='article-image w-full h-4/6'>
                        <img src={`/insights_img/${insights[47].main_image_all}`} alt="" className='w-full h-full object-cover' />
                      </div>
                      <div className="article-title flex flex-col gap-3 text-white">
                        <span className='text-xs'>{insights[47].insights_type}</span>
                        <span>{insights[47].title}</span>
                        <Link to={`/${insights[47].title.replaceAll(' ', '-')}`} className='text-xs text-right'>Read full Insight <i className="fa-solid fa-angle-right"></i></Link>
                      </div>
                    </>
                  )}
                  {hoveredId === 'home' && (
                    <>
                      <div className='article-image w-full h-4/6'>
                        {/* <img src={`/insights_img/${insights[47].main_image_all}`} alt="" className='w-full h-full object-cover' />
                   </div>
                   <div className="article-title flex flex-col gap-3 text-white">
                     <span className='text-xs'>{insights[47].insights_type}</span>
                     <span>{insights[47].title}</span>
                     <Link to={`/${insights[47].title}`} className='text-xs text-right'>Read full Insight <i className="fa-solid fa-angle-right"></i></Link> */}
                      </div>
                    </>
                  )}
                  {hoveredId === 'health' && (
                    <>
                      <div className='article-image w-full h-4/6'>
                        <img src={`/insights_img/${insights[38].main_image_all}`} alt="" className='w-full h-full object-cover' />
                      </div>
                      <div className="article-title flex flex-col gap-3 text-white">
                        <span className='text-xs'>{insights[38].insights_type}</span>
                        <span>{insights[38].title}</span>
                        <Link to={`/${insights[38].title.replaceAll(' ', '-')}`} className='text-xs text-right'>Read full Insight <i className="fa-solid fa-angle-right"></i></Link>
                      </div>
                    </>
                  )}
                  {hoveredId === 'mobility' && (
                    <>
                      <div className='article-image w-full h-4/6'>
                        <img src={`/insights_img/${insights[37].main_image_all}`} alt="" className='w-full h-full object-cover' />
                      </div>
                      <div className="article-title flex flex-col gap-3 text-white">
                        <span className='text-xs'>{insights[37].insights_type}</span>
                        <span>{insights[37].title}</span>
                        <Link to={`/${insights[37].title.replaceAll(' ', '-')}`} className='text-xs text-right'>Read full Insight <i className="fa-solid fa-angle-right"></i></Link>
                      </div>
                    </>
                  )}
                  {hoveredId === 'industry' && (
                    <>
                      <div className='article-image w-full h-4/6'>
                        <img src={`/insights_img/${insights[40].main_image_all}`} alt="" className='w-full h-full object-cover' />
                      </div>
                      <div className="article-title flex flex-col gap-3 text-white">
                        <span className='text-xs'>{insights[40].insights_type}</span>
                        <span>{insights[40].title}</span>
                        <Link to={`/${insights[40].title.replaceAll(' ', '-')}`} className='text-xs text-right'>Read full Insight <i className="fa-solid fa-angle-right"></i></Link>
                      </div>
                    </>
                  )}
                  {hoveredId === 'techno' && (
                    <>
                      <div className='article-image w-full h-4/6'>
                        <img src={`/insights_img/${insights[39].main_image_all}`} alt="" className='w-full h-full object-cover' />
                      </div>
                      <div className="article-title flex flex-col gap-3 text-white">
                        <span className='text-xs'>{insights[39].insights_type}</span>
                        <span>{insights[39].title}</span>
                        <Link to={`/${insights[39].title.replaceAll(' ', '-')}`} className='text-xs text-right'>Read full Insight <i className="fa-solid fa-angle-right"></i></Link>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </li>
            <li className="p-4 border-b-2 border-blue-800 border-opacity-0 hover:border-opacity-100 hover:text-blue-500 duration-200 cursor-pointer">
              <Link to="/labs">Elabs</Link>
            </li>
            <li className="p-4 border-b-2 border-blue-800 border-opacity-0 hover:border-opacity-100 hover:text-blue-500 duration-200 cursor-pointer">
              <Link to="/insights">Insights</Link>
            </li>
            <li className="p-4 border-b-2 border-blue-800 border-opacity-0 hover:border-opacity-100 hover:text-blue-500 duration-200 cursor-pointer">
              <Link to="/sustainability">Sustainability</Link>
            </li>
          </ul>
        </nav>
        <div className="p-4 border-b-4 border-red-800 border-opacity-0 hover:border-opacity-100 hover:text-red-800 duration-200 cursor-pointer relative xs:max-sm:hidden"
          onMouseOver={showCompanyDropdown}
          onMouseOut={hideCompanyDropdown}>
          <p className='text-red-800 font-semibold cursor-pointer hover:text-blue-500 duration-200'>Company</p>
          <div id='company-bar' style={companyDropdownStyle} className='absolute -left-16 -bottom-[194px] bg-white shadow-lg w-64'>
            <ul className='flex flex-col p-4'>
              <Link to='/aboutus'>
                <li className='p-2 text-black hover:bg-gray-200 hover:text-red-800'>
                  About Us
                </li>
              </Link>
              <Link to='/ourpeople'>
                <li className='p-2 text-black hover:bg-gray-200 hover:text-red-800'>
                  Our People
                </li>
              </Link>
              <Link to='/career'>
                <li className='p-2 text-black hover:bg-gray-200 hover:text-red-800'>
                  Careers with Us
                </li>
              </Link>
              <Link to='/contact'>
                <li className='p-2 text-black hover:bg-gray-200 hover:text-red-800'>
                  Get in Touch
                </li>
              </Link>
            </ul>
          </div>
        </div>
      </header>
    </div>
  )
}

export default Navbar;