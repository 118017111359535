import React, { useState } from "react";
import "../pages/ElabsVm.css"
import CTAimg from "../assets/ctaimg.png";
import vmmock from "../assets/vm-mock.png";
import vmVid from "../assets/VisionMap intro video.mp4";
import overview_mock from "../assets/overview_graphics.png";
import analytics_mock from "../assets/analytic_graphic.png";
import dataset_mock from "../assets/dataset_graphics.png";
import multichart_mock from "../assets/multichart_graphics.png";
import lineone from "../assets/Lineone.png";
import linetwo from "../assets/Linetwo.png";
import linethree from "../assets/Linethree.png";
import linefour from "../assets/Linefour.png";
import lineEmWhite from "../assets/lineEmWhite.png";
import { Link } from "react-router-dom";

const ElabsVM = () =>{
    document.title = 'VisionMap';
    // State to track the currently active tab
    const [activeNav, setActiveNav] = useState('');

    // Function to handle navigation item click
    const handleNavClick = (id) => {
      setActiveNav(id);
    };
    return(
        <section className="flex flex-col items-center relative">
            <div className="w-10/12 py-20 z-40 flex items-center justify-center gap-10 xs:max-sm:flex-col">
                <div className="flex flex-col gap-12">
                    <div>
                        <p className="text-base text-red-800">Welcome to VisionMap</p>
                        <h2 className="vmHeading uppercase text-3xl font-semibold">
                            Data-Driven, <br/>
                            Decision-Ready
                        </h2>
                    </div>
                    <Link to={'/contact'} className="w-fit banner-cta text-sm font-semibold flex items-center gap-2 px-5 py-2 bg-white rounded">Schedule A Call<i className="fa fa-chevron-right text-xs" aria-hidden='true'></i></Link>
                    <p className="text-white text-base w-10/12">
                        Transform complex information into clear, actionable insights through a streamlined, 
                        multi-feature interface designed for maximum efficiency. Experience unparalleled ease, 
                        intelligent operation, and enhanced decision-making with every interaction.
                    </p>
                </div>
                <img className="w-6/12 xs:max-sm:w-full" src={vmmock} alt="" />
            </div>
            <div className='sticky top-14 bg-cyan-900 z-50 w-full shadow'>
                <ul class='in-nav-active flex items-center justify-center gap-28 w-full py-5 text-white xs:max-sm:gap-8 xs:max-sm:text-sm'>
                    <a href="#watch"><li className={activeNav === 'watch' ? 'active-inner' : ''} onClick={() => handleNavClick('watch')}>Watch</li></a>
                    <a href="#whyus"><li className={activeNav === 'whyus' ? 'active-inner' : ''} onClick={() => handleNavClick('whyus')}>Why Us</li></a>
                    <a href="#demo"><li className={activeNav === 'demo' ? 'active-inner' : ''} onClick={() => handleNavClick('demo')}>Book a Demo</li></a>
                </ul>
            </div>
            <div id="watch" className="flex flex-col items-center justify-center gap-10 z-40 w-10/12 py-16">
                <div>
                    <h2 className="text-center text-white text-3xl font-semibold uppercase pb-5 xs:max-sm:bg-gradient-to-r xs:max-sm:from-[#024cad] xs:max-sm:to-[#5cdfe6] xs:max-sm:inline-block xs:max-sm:text-transparent xs:max-sm:bg-clip-text xs:max-sm:text-[1.5em] xs:max-sm:text-left xs:max-sm:pt-5">Innovation Beyond Imagination</h2>
                    <p className="text-center text-base text-white xs:max-sm:text-[#003561] xs:max-sm:text-sm xs:max-sm:text-left">An interface that is made to maximize ease with an intelligence of ineffable efficiency.</p>
                </div>
                <video className="h-96 shadow xs:max-sm:h-fit" autoplay="{true}" controls loop muted src={vmVid} />
            </div>
            <p className="z-40 w-10/12 p-10 xs:max-sm:p-5 xs:max-sm:text-sm">
                Meet our Data-Driven, Decision-Ready platform—a powerhouse designed to unlock the full potential of your data. Like a trusted partner, it transforms complex 
                information into clear, actionable insights with ease. With its streamlined, multi-feature interface, it brings you unparalleled efficiency and intelligence, 
                ensuring that every decision you make is informed and effective.
            </p>
            <div id="whyus" className="w-10/12 flex items-center justify-center py-10 xs:max-sm:w-9/12">
                <div className="w-6/12 relative flex justify-start xs:max-sm:hidden">
                    <img className="relative w-96 z-40" src={overview_mock} alt="" />
                    <h2 className="absolute z-30 top-0 right-36 text-9xl font-bold text-cyan-900">1</h2>
                </div>
                <div className="w-6/12 flex flex-col items-end justify-center gap-10 xs:max-sm:w-full">
                    <img className="w-20" src={lineone} alt="" />
                    <div className="flex flex-col items-end justify-center gap-5">
                        <h2 className="text-xl font-semibold">Panoramic Summary</h2>
                        <div className="hidden w-full relative xs:max-sm:flex justify-start">
                            <img className="relative w-96 z-40" src={overview_mock} alt="" />
                            <h2 className="absolute z-30 top-0 right-6 text-9xl font-bold text-cyan-900">1</h2>
                        </div>
                        <p className="text-sm text-justify w-10/12 xs:max-sm:w-full">
                            Powered by AI-derived insights, explore the most essential graphs and charts to kickstart your data analysis journey with the added advantage of 
                            customization options, providing you with the precise visualizations you need.
                        </p>
                    </div>
                </div>
            </div>
            <div className="w-10/12 flex items-center justify-center py-10 xs:max-sm:w-9/12">
                <div className="w-6/12 flex flex-col items-start justify-center gap-10 xs:max-sm:w-full">
                    <img className="w-20" src={linetwo} alt="" />
                    <div className="flex flex-col items-start justify-center gap-5">
                        <h2 className="text-xl font-semibold">Data comes Alive…</h2>
                        <div className="hidden w-full relative xs:max-sm:flex justify-end">
                            <img className="relative w-96 z-40" src={analytics_mock} alt="" />
                            <h2 className="absolute z-30 top-0 left-5 text-9xl font-bold text-cyan-900">2</h2>
                        </div>
                        <p className="text-sm text-justify w-10/12 xs:max-sm:w-full">
                            Unleash your creativity by crafting personalized graphs tailored to your needs, with a wide range of customization options and filters. 
                            Explore endless possibilities, allowing you to visualize data exactly how you envision it.
                        </p>
                    </div>
                </div>
                <div className="w-6/12 relative flex justify-end xs:max-sm:hidden">
                    <img className="relative w-96 z-40" src={analytics_mock} alt="" />
                    <h2 className="absolute z-30 top-0 left-40 text-9xl font-bold text-cyan-900">2</h2>
                </div>
            </div>
            <div className="w-10/12 flex items-center justify-center py-10 xs:max-sm:w-9/12">
                <div className="w-6/12 relative flex justify-start xs:max-sm:hidden">
                    <img className="relative w-96 z-40" src={multichart_mock} alt="" />
                    <h2 className="absolute z-30 top-0 right-36 text-9xl font-bold text-cyan-900">3</h2>
                </div>
                <div className="w-6/12 flex flex-col items-end justify-center gap-10 xs:max-sm:w-full">
                    <img className="w-20" src={linethree} alt="" />
                    <div className="flex flex-col items-end justify-center gap-5">
                        <h2 className="text-xl font-semibold">…with Custom Mosaics</h2>
                        <div className="hidden w-full relative xs:max-sm:flex justify-start">
                            <img className="relative w-96 z-40" src={multichart_mock} alt="" />
                            <h2 className="absolute z-30 top-0 right-3 text-9xl font-bold text-cyan-900">3</h2>
                        </div>
                        <p className="text-sm text-justify w-10/12 xs:max-sm:w-full">
                            Elevate your experience by crafting 100+ customizable graphs on a single screen. Easily adjust each graph for optimal viewing, allowing 
                            you to create the perfect layout that meets your specific analytical needs.
                        </p>
                    </div>
                </div>
            </div>
            <div className="w-10/12 flex items-center justify-center py-10 xs:max-sm:w-9/12">
                <div className="w-6/12 flex flex-col items-start justify-center gap-10 xs:max-sm:w-full">
                    <img className="w-20" src={linefour} alt="" />
                    <div className="flex flex-col items-start justify-center gap-5">
                        <h2 className="text-xl font-semibold">Rich Repository</h2>
                        <div className="hidden w-full relative xs:max-sm:flex justify-end">
                            <img className="relative w-96 z-40" src={dataset_mock} alt="" />
                            <h2 className="absolute z-30 -top-5 right-0 text-9xl font-bold text-cyan-900">4</h2>
                        </div>
                        <p className="text-sm text-justify w-10/12 xs:max-sm:w-full">
                            Perform a detailed analysis of patents with the flexibility to make adjustments as needed. Our platform empowers you to explore and 
                            modify patent data comprehensively, ensuring that your analysis is thorough and tailored to your specific requirements.
                        </p>
                    </div>
                </div>
                <div className="w-6/12 relative flex justify-end xs:max-sm:hidden">
                    <img className="relative w-96 z-40" src={dataset_mock} alt="" />
                    <h2 className="absolute z-30 -top-5 right-0 text-9xl font-bold text-cyan-900">4</h2>
                </div>
            </div>
            <div id="demo" className="w-10/12 py-10 flex items-start justify-center gap-10 xs:max-sm:w-9/12">
                <div className="w-6/12 xs:max-sm:w-full">
                    <p className="text-lg tracking-widest">Connect with us</p>
                    <h2 className="text-2xl font-semibold">We would love to hear<br/>from you...</h2>
                    <div class='pt-5 hidden xs:max-sm:flex flex-col items-center'>
                        <img className="w-6/12" src={CTAimg} alt="" />
                    </div>
                    <p className="text-sm italic bg-slate-200 w-fit px-2 rounded-sm my-5">Revolutionize your workflow with VisionMap!</p>
                    <p className="text-sm py-5">
                        Reach out to us via message or call to explore how our platform can transform your work. We're excited to show you how VisionMap can redefine 
                        your approach and elevate your efficiency.
                    </p>
                    <Link to="/labs" class='border rounded py-2 px-5 bg-white text-black hover:text-red-800 font-semibold'>Book a Demo</Link>
                    
                </div>
                <div class='flex flex-col items-end xs:max-sm:hidden'>
                    <img src={CTAimg} alt="" />
                    <img className="pt-5 w-20" src={lineEmWhite} alt="" />
                </div>
            </div>
            <div className="bg-element"></div>
        </section>

        
    )
}

export default ElabsVM