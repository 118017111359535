// fetchAndReadExcel.js
import * as XLSX from 'xlsx';

const fetchAndReadExcel = async () => {
    const response1 = await fetch('/Industries_content.xlsx');
    const response2 = await fetch('/Services_content.xlsx');
    const response3 = await fetch('/new_template.xlsx');
    const response4 = await fetch('/Q&A.xlsx');
    if (!response1.ok && !response2.ok && !response3.ok && !response4.ok) {
        throw new Error('Network response was not ok');
    }
    const arrayBuffer1 = await response1.arrayBuffer();
    const arrayBuffer2 = await response2.arrayBuffer();
    const arrayBuffer3 = await response3.arrayBuffer();
    const arrayBuffer4 = await response4.arrayBuffer();
    const workbook1 = XLSX.read(arrayBuffer1, { type: 'array' });
    const workbook2 = XLSX.read(arrayBuffer2, { type: 'array' });
    const workbook3 = XLSX.read(arrayBuffer3, { type: 'array' });   
    const workbook4 = XLSX.read(arrayBuffer4, { type: 'array' });   
    // Assuming you want to read the first sheet
    const sheetName1 = workbook1.SheetNames[0];
    const worksheet1 = workbook1.Sheets[sheetName1];
    const data1 = XLSX.utils.sheet_to_json(worksheet1);
    const sheetName2 = workbook2.SheetNames[0];
    const worksheet2 = workbook2.Sheets[sheetName2];
    const data2 = XLSX.utils.sheet_to_json(worksheet2);

    const sheetName3 = workbook3.SheetNames[0];
    const worksheet3 = workbook3.Sheets[sheetName3];
    const data3 = XLSX.utils.sheet_to_json(worksheet3);

    const sheetName4 = workbook4.SheetNames[0];
    const worksheet4 = workbook4.Sheets[sheetName4];
    const data4 = XLSX.utils.sheet_to_json(worksheet4);
    // Convert the sheet to JSON format
    return { data1, data2, data3, data4 };

    // ............................

};

export default fetchAndReadExcel